import { AfterViewInit, DestroyRef, Directive, ElementRef } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { onResize } from '@coin/shared/util-helpers';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { animationFrames, debounce, startWith } from 'rxjs';

/** Enables or disables matTooltip if the attached element has ellipsis overflow and sets the content to the text content.
 * @example `<p class="overflow-ellipsis" coinEllipsisTooltip matTooltip> Hello World! </p>`
 * */
@Directive({
  selector: '[coinEllipsisTooltip][matTooltip]',
  standalone: true
})
export class EllipsisTooltipDirective implements AfterViewInit {
  constructor(
    private matTooltip: MatTooltip,
    private elem: ElementRef<HTMLElement>,
    private destroyRef: DestroyRef
  ) {}

  public ngAfterViewInit(): void {
    onResize(this.elem.nativeElement)
      .pipe(
        startWith(null),
        debounce(() => animationFrames()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.matTooltip.disabled = !isEllipsisActive(this.elem.nativeElement);
        this.matTooltip.message = this.elem.nativeElement.textContent;
      });
  }
}

function isEllipsisActive(element: HTMLElement): boolean {
  return element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight;
}
