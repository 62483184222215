import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadingModule } from '@coin/shared/data-access';
import { FeatureFlagDirective } from '@coin/shared/data-management-directives';
import { FxConversionPipe, FxRatePipe, IndividualMultiplierPipe, IsoToCountryPipe } from '@coin/shared/data-management-pipes';
import { V2ButtonComponent, V2CheckboxComponent, V2DateInputComponent, V2DialogModule, V2DualPillComponent, V2TextAreaComponent } from '@coin/shared/ui-storybook';
import { ControlDisabledDirective, TwoDecimalPlacesDirective } from '@coin/shared/util-directives';
import { EmployeeNamePipe, EnumToTextPipe, FormatUnknownValuePipe, MgmtGroupTranslationPipe, PureFunctionPipe, ThousandsSeparatorPipe, TypeofPipe } from '@coin/shared/util-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import { ArcElement, Chart, PieController } from 'chart.js';
import { SimplebarAngularModule } from 'simplebar-angular';
import { ActionButtonComponent } from './components/action-button/action-button.component';
import { AnimIconComponent } from './components/anim-icon/anim-icon.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CoinTabSelectionComponent } from './components/coin-tab-selection/coin-tab-selection.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { CommentComponent } from './components/comment/comment.component';
import { CommentsComponent } from './components/comments/comments.component';
import { ConfirmChecklistComponent } from './components/confirm-checklist/confirm-checklist.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DividerComponent } from './components/divider/divider.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { IconTextCardComponent } from './components/icon-text-card/icon-text-card.component';
import { InfoTooltipComponent } from './components/info-tooltip/info-tooltip.component';
import { InputComponent } from './components/input/input.component';
import { ListHeaderComponent } from './components/list-header/list-header.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { ListViewFilterTagComponent } from './components/list-view-filter-tag/list-view-filter-tag.component';
import { NoBackendScreenComponent } from './components/no-backend-screen/no-backend-screen.component';
import { RequestSummaryComponent } from './components/request-summary/request-summary.component';
import { SimpleLangSwitchComponent } from './components/simple-lang-switch/simple-lang-switch.component';
import { TextDialogComponent } from './components/text-dialog/text-dialog.component';
import { TextInputOverlayComponent } from './components/text-input-overlay/text-input-overlay.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { TogglePillComponent } from './components/toggle-pill/toggle-pill.component';
import { TopicItemComponent } from './components/topic-item/topic-item.component';
import { TruncatedTextComponent } from './components/truncated-text/truncated-text.component';
import { UnsavedChangesDialogComponent } from './components/unsaved-changes-dialog/unsaved-changes-dialog.component';

@NgModule({
  declarations: [
    CommentComponent,
    CommentsComponent,
    ConfirmationDialogComponent,
    ConfirmChecklistComponent,
    ActionButtonComponent,
    TopicItemComponent,
    NoBackendScreenComponent,
    InputComponent,
    DropdownComponent,
    CheckboxComponent,
    SimpleLangSwitchComponent,
    UnsavedChangesDialogComponent,
    AnimIconComponent,
    TogglePillComponent,
    TextareaComponent,
    TextInputOverlayComponent,
    InfoTooltipComponent,
    DividerComponent,
    RequestSummaryComponent,
    CoinTabSelectionComponent,
    ListViewFilterTagComponent,
    TruncatedTextComponent,
    IconTextCardComponent,
    ListHeaderComponent,
    ListItemComponent,
    TextDialogComponent,
    ColorPickerComponent
  ],
  imports: [
    CommonModule,
    SimplebarAngularModule,
    FormsModule,
    DragDropModule,
    MatIconModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    TranslateModule.forChild(),
    MatOptionModule,
    MatDatepickerModule,
    MatProgressBarModule,
    OverlayModule,
    MatTabsModule,
    MatBadgeModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatButtonModule,
    PureFunctionPipe,
    V2DialogModule,
    ControlDisabledDirective,
    V2DateInputComponent,
    MatRadioModule,
    FormatUnknownValuePipe,
    TypeofPipe,
    EnumToTextPipe,
    IsoToCountryPipe,
    EmployeeNamePipe,
    TwoDecimalPlacesDirective,
    FeatureFlagDirective,
    LoadingModule,
    V2DualPillComponent,
    V2CheckboxComponent,
    V2ButtonComponent,
    QuillModule.forRoot(),
    V2TextAreaComponent
  ],
  exports: [
    CommentComponent,
    CommentsComponent,
    ConfirmationDialogComponent,
    ConfirmChecklistComponent,
    TopicItemComponent,
    NoBackendScreenComponent,
    InputComponent,
    DropdownComponent,
    ActionButtonComponent,
    CheckboxComponent,
    SimpleLangSwitchComponent,
    AnimIconComponent,
    TogglePillComponent,
    TextareaComponent,
    TextInputOverlayComponent,
    InfoTooltipComponent,
    DividerComponent,
    CoinTabSelectionComponent,
    ListViewFilterTagComponent,
    TruncatedTextComponent,
    IconTextCardComponent,
    ListHeaderComponent,
    ListItemComponent,
    ColorPickerComponent
  ],
  providers: [FxRatePipe, FxConversionPipe, MgmtGroupTranslationPipe, EnumToTextPipe, DecimalPipe, ThousandsSeparatorPipe, IndividualMultiplierPipe]
})
export class LegacyComponentsModule {
  constructor() {
    Chart.register(PieController, ArcElement);
  }
}
