import { TinyHelpers } from '@coin/shared/util-helpers';
import { OrgPlanningGuidelineConstraint } from './models/org-planning-org-guideline-constraint.enum';
import { OrgPlanningSupportIssueConfig } from './models/org-planning-support-issue-config.model';

const getTitleKey = (constraint: OrgPlanningGuidelineConstraint): string => {
  return `org-planning-support.${TinyHelpers.pascalcaseToKebabCase(constraint)}-title`;
};

const getSubTitleKey = (constraint: OrgPlanningGuidelineConstraint): string => {
  return `org-planning-support.${TinyHelpers.pascalcaseToKebabCase(constraint)}-sub-title`;
};

export const ORG_GUIDELINE_CONSTRAINT_ISSUE_CONFIGS: Record<OrgPlanningGuidelineConstraint, OrgPlanningSupportIssueConfig> = {
  [OrgPlanningGuidelineConstraint.MinSpanOfControl]: {
    color: 'var(--blue400)',
    svgIcon: 'org_head_white',
    title: getTitleKey(OrgPlanningGuidelineConstraint.MinSpanOfControl),
    subTitle: getSubTitleKey(OrgPlanningGuidelineConstraint.MinSpanOfControl),
    countKey: 'spanOfControlLowCount'
  },
  [OrgPlanningGuidelineConstraint.MaxSpanOfControl]: {
    color: 'var(--blue400)',
    svgIcon: 'org_head_white',
    title: getTitleKey(OrgPlanningGuidelineConstraint.MaxSpanOfControl),
    subTitle: getSubTitleKey(OrgPlanningGuidelineConstraint.MaxSpanOfControl),
    countKey: 'spanOfControlHighCount'
  },
  [OrgPlanningGuidelineConstraint.MaxOrgLevel]: {
    color: 'var(--purple700)',
    icon: 'account_tree',
    title: getTitleKey(OrgPlanningGuidelineConstraint.MaxOrgLevel),
    subTitle: getSubTitleKey(OrgPlanningGuidelineConstraint.MaxOrgLevel),
    countKey: 'maxOrgLevelCount'
  },
  [OrgPlanningGuidelineConstraint.NoHeadPosition]: {
    color: 'var(--orange400)',
    icon: 'person_off',
    title: getTitleKey(OrgPlanningGuidelineConstraint.NoHeadPosition),
    subTitle: getSubTitleKey(OrgPlanningGuidelineConstraint.NoHeadPosition),
    countKey: 'noHeadPositionCount'
  },
  [OrgPlanningGuidelineConstraint.EmptyHeadPosition]: {
    color: 'var(--orange400)',
    icon: 'person_off',
    title: getTitleKey(OrgPlanningGuidelineConstraint.EmptyHeadPosition),
    subTitle: getSubTitleKey(OrgPlanningGuidelineConstraint.EmptyHeadPosition),
    countKey: 'emptyHeadPositionCount'
  },
  [OrgPlanningGuidelineConstraint.MultipleHeadPositions]: {
    color: 'var(--orange400)',
    icon: 'persons',
    title: getTitleKey(OrgPlanningGuidelineConstraint.MultipleHeadPositions),
    subTitle: getSubTitleKey(OrgPlanningGuidelineConstraint.MultipleHeadPositions),
    countKey: 'multipleHeadPositionsCount'
  },
  [OrgPlanningGuidelineConstraint.EmptyOrganisation]: {
    color: 'var(--darkgrey500)',
    icon: 'indeterminate_check_box',
    title: getTitleKey(OrgPlanningGuidelineConstraint.EmptyOrganisation),
    subTitle: getSubTitleKey(OrgPlanningGuidelineConstraint.EmptyOrganisation),
    countKey: 'emptyOrganisationCount'
  }
};
