import { Pipe, PipeTransform } from '@angular/core';
import { LocationInfo } from '../models/location-info.model';

@Pipe({
  name: 'location',
  standalone: true
})
export class LocationPipe implements PipeTransform {
  public transform(locationInfo: LocationInfo): string {
    if (!locationInfo) return '';

    const locationData: string[] = [locationInfo.city, locationInfo.regionIsoCode, locationInfo.countryIsoCode];
    let locationString = locationData.filter(Boolean).join(', ');

    if (locationInfo.locationOffice) locationString = `${locationString} (${locationInfo.locationOffice})`;

    return locationString;
  }
}
