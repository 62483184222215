<div class="box-frame">
  <div class="top">
    <div class="title">
      <mat-icon>subdirectory_arrow_right</mat-icon>
      <h3 class="h2 bold">{{ title }}</h3>
    </div>
    <div class="spacer"></div>
    <button class="no-style close" (click)="closeClicked.emit()" [attr.aria-label]="'org-management.sub-frame.close' | translate">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="container">
    <ng-content></ng-content>
  </div>
</div>
