export enum ActivityStreamItemEventType {
  // Mercer
  EvaluationMember = 'EvaluationMember',
  EvaluationMemberMassUpdate = 'EvaluationMemberMassUpdated',
  JobAssignmentProposal = 'JobAssignmentProposal',
  SpecialPaymentRecord = 'SpecialPaymentRecord',
  SpecialPaymentApproval = 'SpecialPaymentApproval',
  IndividualAllocationValue = 'IndividualAllocationValue',
  EmployeeSnapshotCompensationChanged = 'EmployeeSnapshotCompensationChanged',
  MeritSimulationMemberCalculated = 'MeritSimulationMemberCalculated',
  EmployeeSnapshotManagerChanged = 'EmployeeSnapshotManagerChanged',
  PositionOrganisationAssignmentChanged = 'PositionOrganisationAssignmentChanged',

  // Org Planning
  PositionRecord = 'PositionRecord',
  OrganisationRecord = 'OrganisationRecord',
  EmployeeRecord = 'EmployeeRecord',
  JobAssignmentRecordPositionRecord = 'JobAssignmentRecordPositionRecord',
  JobAssignmentRecordEmployeeRecord = 'JobAssignmentRecordEmployeeRecord',

  // Season
  AutoApproveAllowedChanged = 'AutoApproveAllowedChanged',
  ApprovalRequiredChanged = 'ApprovalRequiredChanged',
  PayrollStateReset = 'PayrollStateReset',
  PayrollExported = 'PayrollExported',
  Record = 'Record',
  SeasonCompensationOverlaid = 'SeasonCompensationOverlaid',
  SeasonBenchmarkUpdateTriggered = 'SeasonBenchmarkUpdateTriggered',
  EmployeeSnapshotDataUpdated = 'EmployeeSnapshotDataUpdated',
  MercerDataTransferred = 'MercerDataTransferred',

  // Succession Management
  TalentRecommendation = 'TalentRecommendation',
  TalentPoolAssignment = 'TalentPoolAssignment',

  // ECL
  TemplateVersionFooterTranslation = 'TemplateVersionFooterTranslation',
  ClauseTranslation = 'ClauseTranslation',

  // HeadcountApproval
  PositionRequest = 'PositionRequest',
  PositionRequestApproval = 'PositionRequestApproval',

  // Standing Position Evaluation
  StandingPositionEvaluationRequestApproval = 'StandingPositionEvaluationRequestApproval',
  StandingPositionEvaluationRequest = 'StandingPositionEvaluationRequest',
  StandingPositionEvaluationMasterDataJobCode = 'StandingPositionEvaluationMasterDataJobCode',
  StandingPositionEvaluationMasterDataPositionClass = 'StandingPositionEvaluationMasterDataPositionClass',
  StandingPositionEvaluationMasterDataEmployee = 'StandingPositionEvaluationMasterDataEmployee'
}
