import { AfterViewChecked, Directive, ElementRef, HostListener } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[showTooltipIfTruncated]',
  providers: [MatTooltip]
})
export class TruncatedTooltipDirective implements AfterViewChecked {
  @HostListener('window:resize')
  public onResize(): void {
    this.setTooltip();
  }

  @HostListener('mouseover')
  public onMouseOver(): void {
    this.matTooltip.show();
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.matTooltip.hide();
  }

  constructor(
    private matTooltip: MatTooltip,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  public ngAfterViewChecked(): void {
    this.setTooltip();
  }

  private setTooltip(): void {
    const element = this.elementRef.nativeElement;
    this.matTooltip.message = element.textContent;
    this.matTooltip.disabled = element.scrollWidth <= element.clientWidth && element.scrollHeight <= element.clientHeight;
  }
}
