import { Injectable } from '@angular/core';
import { ActivityStreamService } from '@coin/modules/activity-stream/data-access';
import {
  ActivityStreamClauseVersionLoadConfig,
  ActivityStreamEntityType,
  ActivityStreamEvaluationMemberLoadConfig,
  ActivityStreamHeadcountApprovalPositionRequestLoadConfig,
  ActivityStreamIndividualMultiplierLoadConfig,
  ActivityStreamItem,
  ActivityStreamLoadConfig,
  ActivityStreamMercerMasterDataLoadConfig,
  ActivityStreamMeritLoadConfig,
  ActivityStreamOrgManagementOrgLoadConfig,
  ActivityStreamOrgPlanningEmployeeLoadConfig,
  ActivityStreamOrgPlanningOrganisationLoadConfig,
  ActivityStreamOrgPlanningPositionLoadConfig,
  ActivityStreamRecordLoadConfig,
  ActivityStreamSeasonLoadConfig,
  ActivityStreamSpecialPaymentProposalLoadConfig,
  ActivityStreamStandingPositionEvaluationLoadConfig,
  ActivityStreamSuccessionManagementTalentPoolLoadConfig,
  ActivityStreamTemplateVersionLoadConfig
} from '@coin/modules/activity-stream/util';
import { ListViewTagFilterParameter, PaginatedResult, PaginationState } from '@coin/shared/util-models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  AddActivityStreamFilterParameter,
  ClearActivityStreamFilterParameterKey,
  LoadActivityStreamItems,
  RemoveActivityStreamFilterParameter,
  ResetActivityStreamState,
  SetActivityStreamEntityType,
  SetActivityStreamLoadConfig
} from './activity-stream.actions';

const STATE_DEFAULTS: ActivityStreamStateModel = {
  items: [],
  pagination: {
    currentPage: 0,
    hasMore: true,
    pageSize: 25,
    total: 0
  },
  filterParameter: [],
  type: undefined,
  loadConfig: undefined
};

interface ActivityStreamStateModel {
  items: ActivityStreamItem[];
  pagination: PaginationState;
  filterParameter: ListViewTagFilterParameter[];
  type: ActivityStreamEntityType;
  loadConfig: ActivityStreamLoadConfig;
}

@State<ActivityStreamStateModel>({
  name: 'ActivityStreamState',
  defaults: STATE_DEFAULTS
})
@Injectable()
export class ActivityStreamState {
  constructor(private activityStreamService: ActivityStreamService) {}

  @Selector()
  static items(state: ActivityStreamStateModel): ActivityStreamItem[] {
    return state.items?.filter(item => {
      if (!state.filterParameter?.length) {
        return true;
      }

      return this.isItemInFilter(item, state.filterParameter);
    });
  }

  @Selector()
  static filterParameter(state: ActivityStreamStateModel): ListViewTagFilterParameter[] {
    return state.filterParameter;
  }

  @Action(LoadActivityStreamItems, { cancelUncompleted: true })
  loadActivityStreamItems(ctx: StateContext<ActivityStreamStateModel>, { resetPaging }: LoadActivityStreamItems): Observable<PaginatedResult<ActivityStreamItem>> {
    const { pagination, loadConfig, items } = ctx.getState();
    const { total, currentPage, pageSize } = pagination;
    // Typically "hasMore" would be better, but BE doesn't like to fill properties correctly.
    if (total <= items.length && !resetPaging) {
      return;
    }

    const newConfig = {
      ...loadConfig,
      page: resetPaging ? 1 : currentPage + 1,
      size: pageSize
    };
    ctx.patchState({ loadConfig: newConfig });
    return this.getActivityStreamItemsLoad(ctx.getState().type, newConfig).pipe(
      tap(data => ctx.patchState({ items: resetPaging ? data.content : [...items, ...data.content], pagination: data }))
    );
  }

  @Action(SetActivityStreamEntityType)
  setActivityStreamEntityType(ctx: StateContext<ActivityStreamStateModel>, { payload }: SetActivityStreamEntityType): void {
    ctx.patchState({ type: payload });
  }

  @Action(SetActivityStreamLoadConfig)
  setActivityStreamLoadConfig(ctx: StateContext<ActivityStreamStateModel>, { payload }: SetActivityStreamLoadConfig): void {
    ctx.patchState({ loadConfig: payload });
  }

  @Action(AddActivityStreamFilterParameter)
  addActivityStreamFilterParameter(ctx: StateContext<ActivityStreamStateModel>, { payload }: AddActivityStreamFilterParameter): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        const previousFilterIndex = state.filterParameter.findIndex(filter => filter.category === payload.category && filter.value === payload.value);
        if (previousFilterIndex > -1) {
          const previousFilter = state.filterParameter[previousFilterIndex];
          state.filterParameter[previousFilterIndex] = { ...previousFilter, ...payload };
        } else {
          state.filterParameter.push(payload);
        }
      })
    );
  }

  @Action(RemoveActivityStreamFilterParameter)
  removeActivityStreamFilterParameter(ctx: StateContext<ActivityStreamStateModel>, { payload }: RemoveActivityStreamFilterParameter): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.filterParameter = state.filterParameter.filter(filter => filter.category !== payload.category || filter.value !== payload.value);
      })
    );
  }

  @Action(ClearActivityStreamFilterParameterKey)
  clearActivityStreamFilterParameterKey(ctx: StateContext<ActivityStreamStateModel>, { category }: ClearActivityStreamFilterParameterKey): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.filterParameter = state.filterParameter.filter(param => param.category !== category);
      })
    );
  }

  @Action(ResetActivityStreamState)
  resetActivityStreamState(ctx: StateContext<ActivityStreamStateModel>): void {
    ctx.patchState(STATE_DEFAULTS);
  }

  private getActivityStreamItemsLoad(type: ActivityStreamEntityType, loadConfig: ActivityStreamLoadConfig): Observable<PaginatedResult<ActivityStreamItem>> {
    const activityStreamItemsLoadConfig = {
      [ActivityStreamEntityType.EvaluationMember]: () => this.activityStreamService.getEvaluationMemberActivityStreamItems(loadConfig as ActivityStreamEvaluationMemberLoadConfig),
      [ActivityStreamEntityType.IndividualMultiplier]: () =>
        this.activityStreamService.getIndividualMultiplierActivityStreamItems(loadConfig as ActivityStreamIndividualMultiplierLoadConfig),
      [ActivityStreamEntityType.Merit]: () => this.activityStreamService.getMeritActivityStreamItems(loadConfig as ActivityStreamMeritLoadConfig),
      [ActivityStreamEntityType.SpecialPayment]: () =>
        this.activityStreamService.getSpecialPaymentProposalActivityStreamItems(loadConfig as ActivityStreamSpecialPaymentProposalLoadConfig),
      [ActivityStreamEntityType.OrgPlanningOrganisation]: () =>
        this.activityStreamService.getOrgPlanningOrganisationActivityStreamItems(loadConfig as ActivityStreamOrgPlanningOrganisationLoadConfig),
      [ActivityStreamEntityType.OrgPlanningPosition]: () =>
        this.activityStreamService.getOrgPlanningPositionActivityStreamItems(loadConfig as ActivityStreamOrgPlanningPositionLoadConfig),
      [ActivityStreamEntityType.OrgPlanningEmployee]: () =>
        this.activityStreamService.getOrgPlanningEmployeeActivityStreamItems(loadConfig as ActivityStreamOrgPlanningEmployeeLoadConfig),
      [ActivityStreamEntityType.Record]: () => this.activityStreamService.getRecordActivityStream(loadConfig as ActivityStreamRecordLoadConfig),
      [ActivityStreamEntityType.Season]: () => this.activityStreamService.getSeasonActivityStream(loadConfig as ActivityStreamSeasonLoadConfig),
      [ActivityStreamEntityType.SuccessionManagementTalentPool]: () =>
        this.activityStreamService.getSuccessionManagementActivityStreamItems(loadConfig as ActivityStreamSuccessionManagementTalentPoolLoadConfig),
      [ActivityStreamEntityType.ClauseVersion]: () => this.activityStreamService.getClauseVersionActivityStream(loadConfig as ActivityStreamClauseVersionLoadConfig),
      [ActivityStreamEntityType.TemplateVersion]: () => this.activityStreamService.getTemplateVersionActivityStream(loadConfig as ActivityStreamTemplateVersionLoadConfig),
      [ActivityStreamEntityType.MercerMasterData]: () => this.activityStreamService.getMercerMasterDataActivityStreamItems(loadConfig as ActivityStreamMercerMasterDataLoadConfig),
      [ActivityStreamEntityType.PositionRequest]: () =>
        this.activityStreamService.getHeadcountApprovalPositionRequestActivityStreamItems(loadConfig as ActivityStreamHeadcountApprovalPositionRequestLoadConfig),
      [ActivityStreamEntityType.StandingPositionEvaluation]: () =>
        this.activityStreamService.getStandingPositionEvaluationActivityStreamItems(loadConfig as ActivityStreamStandingPositionEvaluationLoadConfig),
      [ActivityStreamEntityType.OrgManagementOrganisation]: () =>
        this.activityStreamService.getOrgManagementActivityStreamItems(loadConfig as ActivityStreamOrgManagementOrgLoadConfig)
    } satisfies Record<ActivityStreamEntityType, () => Observable<PaginatedResult<ActivityStreamItem>>>;

    return activityStreamItemsLoadConfig[type]();
  }

  private static getAggregatedFilterParamater(filterParameter: ListViewTagFilterParameter[]): Record<string, string[]> {
    return filterParameter.reduce((acc, parameter) => ({ ...acc, [parameter.category]: [...(acc[parameter.category] ?? []), parameter.value] }), {});
  }

  private static isItemInFilter(item: ActivityStreamItem, filterParameter: ListViewTagFilterParameter[]): boolean {
    return Object.entries(this.getAggregatedFilterParamater(filterParameter)).every(([key, values]) => {
      return (values as string[])?.some(value => {
        const dateFormat = 'MM/DD/YYYY';
        const isDate = moment(value, dateFormat).isValid();

        return !isDate
          ? JSON.stringify(item[key] || 'system')
              ?.toLowerCase()
              ?.includes(value?.toLowerCase())
          : moment.utc(item[key]).startOf('day').valueOf() <= moment.utc(value, dateFormat).valueOf();
      });
    });
  }
}
