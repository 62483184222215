<button class="no-style burger-wrapper" (click)="onSidebarToggle()" [attr.aria-label]="(isSidebarVisible ? 'side-menu.close' : 'side-menu.open') | translate">
  <div class="menu-burger" id="menu-bar-close-open">
    @if (isSidebarVisible) {
      <mat-icon @animateEnterLeave>close</mat-icon>
    }
    @if (!isSidebarVisible) {
      <mat-icon @animateEnterLeave>menu</mat-icon>
    }
  </div>
</button>
<div class="components" role="menubar" [class.sidebar-visible]="isSidebarVisible" [@showMenu]="isSidebarVisible ? 'active' : 'inactive'">
  <!-- Apps -->
  <cosmos-sidebar-menu-item [isContentVisible]="selectedMenuItem === titles[0]" [header]="titles[0]" (toggle)="toggleMenuItem(titles[0])" role="menuitem">
    <coin-siemens-energy-coin-apps-overview [coinBaseUrl]="environment.customerUrl"></coin-siemens-energy-coin-apps-overview>
  </cosmos-sidebar-menu-item>
</div>
<cosmos-collapsed-bar
  [class.sidebar-visible]="isSidebarVisible"
  (toggleItem)="onSidebarToggle($event)"
  [titles]="titles"
  role="menubar"
  [@showMenu]="isSidebarVisible ? 'inactive' : 'active'"></cosmos-collapsed-bar>
