<div>
  @switch (isIntegrated) {
    @case (true) {
      <ng-container [ngTemplateOutlet]="calculationContainer"></ng-container>
    }
    @case (false) {
      <div cdkOverlayOrigin #origin="cdkOverlayOrigin"></div>
      <ng-template cdkConnectedOverlay [cdkConnectedOverlayOpen]="isCalculating" [cdkConnectedOverlayHasBackdrop]="true" [cdkConnectedOverlayOrigin]="origin">
        <ng-container [ngTemplateOutlet]="calculationContainer"></ng-container>
      </ng-template>
    }
  }
</div>

<ng-template #calculationContainer>
  @if (isCalculating) {
    <div class="recalculation-container" [class.mat-elevation-z4]="!isIntegrated" [class.is-integrated]="isIntegrated">
      <div class="info">
        <div class="top">
          @if (!isIntegrated) {
            <p class="bold">{{ calculationHeadline }}</p>
          }
          @if (transactionCalculationOperations && !isIntegrated) {
            <coin-action-button
              class="primary"
              [small]="true"
              permissionAccess
              [allowedResources]="[permissionResource.TransactionStatus]"
              [allowedActions]="[permissionActions.Update]"
              (click)="cancelTransactionProcess()"
              >{{ 'general.btnCancel' | translate }}</coin-action-button
            >
          }
        </div>

        @if (transactionCalculationOperations?.displayAsList) {
          @for (processStep of transactionCalculationOperations?.transactionStatus?.processSteps ?? []; track stepNr($index, processStep)) {
            <div class="process-step-line">
              <div class="process-step-icon">
                @if (processStep.transactionState === 'Running' || processStep.transactionState === 'Started') {
                  <app-turbine [withPole]="false" [shadow]="false" [contrast]="true"></app-turbine>
                }
                @if (processStep.transactionState === 'Finished') {
                  <mat-icon>done</mat-icon>
                }
              </div>

              <p>{{ processStep.title }} {{ transactionCalculationOperations?.showProgressCount ? '(' + processStep.progressCount + '/' + processStep.totalCount + ')' : '' }}</p>
            </div>
          }
        } @else {
          @if (currentProcessStepTitle) {
            <p>{{ currentProcessStepTitle }}</p>
          }
        }
      </div>
      <div class="progress">
        <mat-progress-bar mode="determinate" [value]="calculationProgress"></mat-progress-bar>
        <div class="turbine" [style.left]="leftTurbineStyleValue">
          <app-turbine [withPole]="false" [shadow]="false"></app-turbine>
        </div>
      </div>
      <div class="bottom">
        @if (isHideable) {
          <coin-action-button
            class="secondary"
            [small]="true"
            permissionAccess
            [allowedResources]="[permissionResource.TransactionStatus]"
            [allowedActions]="[permissionActions.Update]"
            (click)="hide()"
            >{{ 'general.hide' | translate }}</coin-action-button
          >
        }
      </div>
    </div>
  }
</ng-template>
