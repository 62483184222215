import { Injectable } from '@angular/core';
import { SearchResult, SearchResultGroup, SearchResultType } from '@coin/modules/group-search/util';
import { ImmerComponentStore } from 'ngrx-immer/component-store';
import { Observable } from 'rxjs';

interface SearchStateModel {
  currentSearchString: string;
  recentSearchResults: SearchResult[];
  maxRecentSearchResults: number;
  selectedFilter: SearchResultType;
}

const STATE_DEFAULTS: SearchStateModel = {
  currentSearchString: '',
  recentSearchResults: [],
  maxRecentSearchResults: 5,
  selectedFilter: undefined
};

@Injectable()
export class SearchState extends ImmerComponentStore<SearchStateModel> {
  currentSearchString$ = this.select(({ currentSearchString }) => currentSearchString);
  recentSearchResults$ = this.select(({ recentSearchResults }) => recentSearchResults);

  get currentSearchString(): string {
    return this.get().currentSearchString;
  }

  constructor() {
    super(STATE_DEFAULTS);
  }

  public isFilterActive(filter: SearchResultType): boolean {
    return this.get().selectedFilter === filter;
  }

  public showSearchResults(resultType: SearchResultType): boolean {
    return !this.get().selectedFilter || this.get().selectedFilter === resultType;
  }

  public loadSearchResults(searchString: string): Observable<void> {
    const trimmedSearchString = searchString.trim();

    if (this.get().currentSearchString === trimmedSearchString) {
      return;
    }

    this.setState(state => {
      state.currentSearchString = trimmedSearchString;
    });
  }

  public resetSearchResults(): void {
    this.setState(state => {
      state.currentSearchString = STATE_DEFAULTS.currentSearchString;
    });
  }

  public setSelectedFilter(filter: SearchResultType): void {
    this.setState(state => {
      state.selectedFilter = filter;
    });
  }

  public loadRecentSearchResults(searchResultGroups: SearchResultGroup[], resultCacheKey: string): void {
    let recentResults: SearchResult[] = [];
    try {
      const parsedResults: SearchResult[] = JSON.parse(localStorage.getItem(resultCacheKey)) ?? [];

      if (parsedResults.length > 0) {
        parsedResults.forEach(result => {
          const matchingGroup = searchResultGroups.find(group => group.matchesResult(result));
          if (!matchingGroup) return;

          const { searchResultClass } = matchingGroup;
          recentResults.push(new searchResultClass(result));
        });
      }
    } catch {
      recentResults = [];
    }

    this.setState(state => {
      state.recentSearchResults = recentResults;
    });
  }

  public saveRecentSearchResult(result: SearchResult, resultCacheKey: string): void {
    const recentSearches = [...this.get().recentSearchResults].filter(recentSearch => recentSearch.id !== result.id);
    const maxRecent = this.get().maxRecentSearchResults;

    recentSearches.unshift(result);

    while (recentSearches.length > maxRecent) {
      recentSearches.pop();
    }

    localStorage.setItem(resultCacheKey, JSON.stringify(recentSearches));
    this.setState(state => {
      state.recentSearchResults = recentSearches;
    });
  }
}
