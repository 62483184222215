<div class="box-frame">
  <div class="top">
    <div class="title">
      <h2 class="bold">{{ title }}</h2>
    </div>
    <ng-content select="[actions]"></ng-content>
  </div>

  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
