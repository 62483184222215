import { ChangeDetectionStrategy, Component, Input, OnInit, signal } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-turbine',
  templateUrl: './turbine.component.html',
  styleUrls: ['./turbine.component.scss'],
  animations: [
    trigger('shrinkExpand', [
      transition(':enter', [
        style({ height: '0px', minWidth: '0px', maxWidth: '0px', opacity: 0 }),
        animate('350ms cubic-bezier(0.1, -0.6, 0.2, 0)', style({ height: '*', minWidth: '*', maxWidth: '*', opacity: 1 }))
      ]),
      transition(':leave', [animate('350ms cubic-bezier(0.1, -0.6, 0.2, 0)', style({ height: '0px', minWidth: '0px', maxWidth: '0px', opacity: 0 }))])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TurbineComponent implements OnInit {
  @Input() speed = '';
  @Input() contrast = false;
  @Input() withPole = true;
  @Input() shadow = true;
  @Input() text = false;

  currLoadingNum = signal(0);
  reload = signal(false);

  ngOnInit() {
    if (this.text) {
      this.updateLoadingNum();
    }
  }

  updateLoadingNum() {
    setTimeout(() => {
      if (this.currLoadingNum() < 5) {
        this.reload.set(true);
        setTimeout(() => {
          this.currLoadingNum.update(num => num + 1);
          this.reload.set(false);
          this.updateLoadingNum();
        }, 350);
      }
    }, 4000);
  }
}
