export enum ActivityStreamEntityType {
  EvaluationMember,
  IndividualMultiplier,
  Merit,
  SpecialPayment,
  OrgPlanningOrganisation,
  OrgPlanningPosition,
  OrgPlanningEmployee,
  Season,
  Record,
  SuccessionManagementTalentPool,
  ClauseVersion,
  TemplateVersion,
  PositionRequest,
  MercerMasterData,
  StandingPositionEvaluation,
  OrgManagementOrganisation
}
