<div class="content">
  @if (isHeaderVisible) {
    <cosmos-header [isSidebarVisible]="isSideBarVisible$ | async"></cosmos-header>
  }
  <main [class.full-height]="!isHeaderVisible" [class.full-width]="!(isSideBarVisible$ | async)">
    <router-outlet></router-outlet>
    @if (isSideBarVisible$ | async) {
      <cosmos-sidebar role="navigation"> </cosmos-sidebar>
    }
  </main>
  <footer></footer>
</div>
