<button (click)="toggleMenu()" class="no-style help-menu-toggle">
  <mat-icon svgIcon="help_icon"></mat-icon>
  <p class="text bold" [matBadge]="totalNewFlags" [matBadgeHidden]="totalNewFlags === 0" [matBadgeOverlap]="true">{{ 'header.help-menu.header' | translate }}</p>
</button>

@if (isHelpMenuVisible) {
  <div @slideFaq class="help-menu">
    <coin-siemens-energy-faq-menu
      [helpData]="helpData[languageSelected]"
      [isLoading]="isHelpDataLoading"
      [isSearchBarVisible]="false"
      (close)="toggleMenu()"
      (openOtherTopic)="onOpenOtherFaqTopic($event)"></coin-siemens-energy-faq-menu>
  </div>
}
