import { EmployeeQuickProfile, Position } from '../../../../api/generated';

export class LoadEmployeeProfile {
  static readonly type = '[Quickdraw Profile] Load Employee Profile';
  constructor(public readonly employeeId: string) {}
}

export class EmployeeProfileLoaded {
  static readonly type = '[Quickdraw Profile] Employee Profile loaded';
  constructor(
    public readonly employeeId: string,
    public readonly quickProfile: EmployeeQuickProfile
  ) {}
}

export class LoadPositions {
  static readonly type = '[Quickdraw Profile] Load Employee Positions';
  constructor(public readonly employeeId: string) {}
}

export class PositionsLoaded {
  static readonly type = '[Quickdraw Profile] Employee Positions Loaded';
  constructor(
    public readonly employeeId: string,
    public readonly positions: Position[]
  ) {}
}
