import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { SearchResult, SearchResultGroup, SearchResultType } from '@coin/modules/group-search/util';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { SearchState } from '../../state/search.state';

@Component({
  selector: 'coin-group-search-results',
  templateUrl: './group-search-results.component.html',
  styleUrls: ['./group-search-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SearchState]
})
export class GroupSearchResultsComponent implements OnInit, OnChanges {
  @Input({ required: true, transform: (s: string) => s?.trim() }) public searchString: string;
  @Input({ required: true }) searchResultGroups: SearchResultGroup[] = [];
  @Input() resultCacheKey: string;
  @Output() navigatedToResult = new EventEmitter();
  @Output() initializeSearchLambda = new EventEmitter();

  @ViewChildren('searchResult') public searchResults: QueryList<ElementRef>;

  private searchTrigger$ = new Subject<string>();

  recentSearchResults$ = this.searchState.recentSearchResults$;

  constructor(
    private searchState: SearchState,
    private router: Router
  ) {
    this.searchTrigger$.pipe(debounceTime(300), distinctUntilChanged(), takeUntilDestroyed()).subscribe(searchString => {
      if (searchString) {
        this.searchState.loadSearchResults(searchString);
        this.searchResultGroups?.forEach(group => group.loadResults(searchString));
      } else {
        this.searchState.resetSearchResults();
        this.searchResultGroups?.forEach(group => group.resetSearchResults());
      }
    });
    this.initializeSearchLambda.emit();
  }

  public ngOnInit(): void {
    if (this.resultCacheKey) {
      this.searchState.loadRecentSearchResults(this.searchResultGroups, this.resultCacheKey);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchString) {
      this.searchTrigger$.next(changes.searchString.currentValue);
    }

    if (changes.searchResultGroups && !changes.searchResultGroups.isFirstChange()) {
      throw new Error('result groups cannot be changed after init');
    }
  }

  public navigateToResult(result: SearchResult, saveInRecentSearchResults: boolean): void {
    this.router.navigate(result.routerLink, { queryParams: result.queryParams, queryParamsHandling: result.queryParamsHandling });

    this.navigatedToResult.emit();

    if (this.resultCacheKey && saveInRecentSearchResults) {
      this.searchState.saveRecentSearchResult(result, this.resultCacheKey);
    }
  }

  public isFilterActive(filter: SearchResultType): boolean {
    return this.searchState.isFilterActive(filter);
  }

  public showSearchResults(resultType: SearchResultType): boolean {
    return this.searchState.showSearchResults(resultType);
  }

  public toggleFilter(filter: SearchResultType): void {
    if (this.isFilterActive(filter)) {
      this.searchState.setSelectedFilter(undefined);
    } else {
      this.searchState.setSelectedFilter(filter);
    }
  }

  public loadMoreResults(resultType: SearchResultType): void {
    this.searchResultGroups.find(group => group.resultType === resultType).loadMoreResults(this.searchState.currentSearchString);

    // Focus last element to keep focus in search results
    this.getLastSearchResult(resultType).nativeElement.focus();
  }

  private getLastSearchResult(resultType: SearchResultType): ElementRef {
    const { titleKey } = this.searchResultGroups.find(group => group.resultType === resultType);
    const filteredResults = this.searchResults.filter(result => result.nativeElement.name === titleKey);
    return filteredResults.pop();
  }
}
