<button
  (click)="msTeamsService.openTeamsChat([employeeProfile.contactInfo.email])"
  [matTooltip]="(hasValidMailAddress ? 'employee-profile.actions.teams' : 'employee-profile.actions.unknown-email-tooltip') | translate"
  [attr.aria-label]="'employee-profile.actions.teams' | translate"
  [disabled]="!hasValidMailAddress"
  class="no-style">
  <mat-icon svgIcon="ms_teams"></mat-icon>
</button>
<button
  (click)="sendMail()"
  (contextmenu)="hasValidMailAddress ? copyMail($event) : undefined"
  [matTooltip]="
    hasValidMailAddress
      ? ('employee-profile.actions.send-email-tooltip' | translate) + '\n' + ('employee-profile.actions.copy-email-tooltip' | translate)
      : ('employee-profile.actions.unknown-email-tooltip' | translate)
  "
  [attr.aria-label]="'employee-profile.actions.email' | translate"
  [disabled]="!hasValidMailAddress"
  class="no-style">
  <mat-icon>email</mat-icon>
</button>
<button
  (click)="scheduleMeeting()"
  [matTooltip]="(hasValidMailAddress ? 'employee-profile.actions.meeting' : 'employee-profile.actions.unknown-email-tooltip') | translate"
  [attr.aria-label]="'employee-profile.actions.meeting' | translate"
  [disabled]="!hasValidMailAddress"
  class="no-style">
  <mat-icon>event</mat-icon>
</button>
<button (click)="downloadVcf()" [matTooltip]="'employee-profile.actions.download' | translate" [attr.aria-label]="'employee-profile.actions.download' | translate" class="no-style">
  <mat-icon>save_alt</mat-icon>
</button>
