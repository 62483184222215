<div class="faq-menu" [ngClass]="{ 'more-width': faqMoreWidth() }">
  <div class="faq-top-bar">
    <button class="no-style top-faq-text" (click)="reduceOnePage()" [ngClass]="{ 'top-faq-text--return': faqTabIndex() > 0 || generalTabIndex() > 0 }" aria-label="Faq Back Button">
      {{ (faqTabIndex() > 0 ? topicTitle() : 'faq.' + topicTitle()) | translate }}
      @if (subtitle()) {
        <span @shrinkExpand> - {{ subtitle() }}</span>
      }
    </button>
    <div class="faq-close-container">
      <coin-action-button (click)="closeFaq()" class="secondary faq-close" svgIcon="cancel" btnPadding="0.55em 0.5em" [iconTextMargin]="0" role="button" aria-label="Close Faq">
        <p class="bold"></p>
      </coin-action-button>
    </div>
  </div>
  @if (!helpData && !isLoading) {
    <div class="error-container">
      <div class="warning-icon">
        <mat-icon>warning</mat-icon>
      </div>
      <div class="warning-text">
        <p class="top-faq-text">{{ 'faq.no-faq-load-info' | translate }}</p>
        <p>{{ 'faq.reload-page-info' | translate }}</p>
      </div>
    </div>
  }
  @if (!isLoading && helpData) {
    @if (isSearchBarVisible) {
      <div class="faq-search-input">
        <coin-input
          class="faq-search"
          [placeholder]="'general.search-faq' | translate"
          (input)="searchFaq()"
          icon="search"
          [(ngModel)]="search"
          type="text"
          aria-label="Faq Search"></coin-input>
      </div>
    }
    <div class="menu-container">
      <mat-tab-group [selectedIndex]="generalTabIndex()" class="main-tab-height">
        <mat-tab>
          <mat-tab-group [selectedIndex]="faqTabIndex()" class="main-tab-height">
            <mat-tab>
              <coin-siemens-energy-faq-top-level
                class="faq-base-tab"
                [items]="helpData"
                (openFaq)="openFaqFromSub($event)"
                (openOther)="onOpenOtherTopic($event)"></coin-siemens-energy-faq-top-level>
            </mat-tab>
            @for (tab of faqTabs(); track tab) {
              <mat-tab>
                @if (tab?.content?.length === 0) {
                  <p class="faq-padding">{{ 'faq.no-items-found' | translate }}</p>
                }
                @if (!tab.isAnswer) {
                  <coin-siemens-energy-faq-inbetween-level class="faq-base-tab" [items]="tab" (openFaq)="openFaqFromSub($event)"></coin-siemens-energy-faq-inbetween-level>
                }
                @if (tab.isAnswer) {
                  <coin-siemens-energy-faq-answer-level class="faq-base-tab" [items]="tab"></coin-siemens-energy-faq-answer-level>
                }
              </mat-tab>
            }
          </mat-tab-group>
        </mat-tab>
      </mat-tab-group>
    </div>
  } @else {
    @if (isLoading) {
      <div class="loading-container">
        <app-loading-screen [local]="true" [contrast]="true"></app-loading-screen>
      </div>
    }
  }
  <div>
    <ng-template #loading>
      @if (isLoading) {
        <div class="loading-container">
          <app-loading-screen [local]="true" [contrast]="true"></app-loading-screen>
        </div>
      }
    </ng-template>
  </div>
</div>
