<div class="container">
  <div class="mat-elevation-z2 maintenance-main">
    <div class="maintenance-image">
      @if (!hideTimeInfo) {
        <div class="maintenance-time">
          <div class="maintenance-time--frame"></div>
          <p class="maintenance-time--back">{{ 'general.system-unavailable-back-at' | translate }}</p>
          <p class="bold maintenance-time--time-text">{{ 'general.system-unavailable-time' | translate }}</p>
        </div>
      }
    </div>
    <div class="maintenance-text">
      <h3>{{ 'general.system-unavailable-headline' | translate }}</h3>
      <p [innerHTML]="'general.system-unavailable' | translate"></p>
    </div>
  </div>
</div>
