import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { Type } from '@angular/core';
import { SearchResultType } from '../enums/search-result-type.enum';
import { SearchResult } from './search-result.model';
import { SearchResultList } from './search-result-list.model';
import { getResultCountString } from '../get-result-count-string.function';

export abstract class SearchResultGroup {
  abstract resultType: SearchResultType;
  abstract titleKey: string;
  abstract noResultsMessageKey: string;
  abstract hideResultsDescriptionKey: string;
  abstract showResultsDescriptionKey: string;
  abstract icon?: string;
  abstract svgIcon?: string;
  abstract searchResultClass: Type<SearchResult>;

  public maxResultsPerRequest: number = 10;

  public isLoading$ = new BehaviorSubject(false);
  public results$ = new BehaviorSubject<SearchResult[]>([]);
  public resultsCount$ = new BehaviorSubject(0);
  public resultsCountRelation$ = new BehaviorSubject<string>(undefined);
  public hasResults$ = this.results$.pipe(map(results => results?.length > 0));

  public hasMoreResults$: Observable<boolean> = combineLatest([this.isLoading$, this.results$, this.resultsCount$]).pipe(
    map(([isLoading, results, resultsCount]) => !isLoading && results.length < resultsCount)
  );

  public resultsCountDisplayString$: Observable<string> = combineLatest([this.resultsCount$, this.resultsCountRelation$]).pipe(
    map(([count, relation]) => getResultCountString(count, relation))
  );

  matchesResult(result: SearchResult): boolean {
    return result.resultType === this.resultType;
  }

  constructor(protected searchFunction: (searchString: string, startFrom?: number, maxResults?: number) => Observable<SearchResultList>) {}

  public loadResults(searchString: string): void {
    this.isLoading$.next(true);
    this.results$.next([]);

    this.searchFunction(searchString, this.results$.value?.length, this.maxResultsPerRequest).subscribe(result => {
      this.resultLoaded(result);
    });
  }

  public loadMoreResults(currentSearchString: string): void {
    this.isLoading$.next(true);

    this.searchFunction(currentSearchString, this.results$.value?.length, this.maxResultsPerRequest).subscribe(result => {
      this.resultLoaded(result, true);
    });
  }

  public resetSearchResults(): void {
    this.isLoading$.next(true);
    this.results$.next([]);
  }

  private resultLoaded(result: SearchResultList, extendResults = false): void {
    this.isLoading$.next(false);
    this.resultsCount$.next(result.count);
    this.resultsCountRelation$.next(result.countRelation);
    this.results$.next(extendResults ? [...this.results$.value, ...result.results] : result.results);
  }
}
