import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  template: `<app-loading-screen [contrast]="true"></app-loading-screen>`
})
export class OrgChartsSocCompatibilityComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  public async ngOnInit(): Promise<void> {
    this.redirectToOrgManagement(await this.convertQueryParams());
  }

  private async convertQueryParams(): Promise<Params> {
    const socQueryParams = this.activatedRoute.snapshot.queryParams;
    const convertedQueryParams: Params = {};

    if (socQueryParams?.organizationUniqueName) {
      convertedQueryParams.orgCode = socQueryParams.organizationUniqueName;

      if (socQueryParams?.userUniqueName) {
        convertedQueryParams.employeeGid = socQueryParams.userUniqueName;
        convertedQueryParams.employeeOrgCode = socQueryParams.organizationUniqueName;
      }
    }

    return convertedQueryParams;
  }

  private redirectToOrgManagement(queryParams: Params): void {
    this.router.navigate(['org-management'], { queryParams });
  }
}
