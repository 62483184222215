import { EmployeeSlim, EmptyGuid, Position } from '@coin/shared/util-models';
import { Employee, Position as ApiPosition } from '../../api/generated';

export class MappingHelpers {
  public static mapEmployee(employee: Employee): EmployeeSlim {
    return employee && employee.id !== EmptyGuid
      ? {
          ...employee,
          firstname: employee.firstname,
          lastname: employee.lastname,
          middlename: employee.middlename,
          lastnamePrefix: employee.lastnamePrefix
        }
      : undefined;
  }

  public static mapPosition(position: ApiPosition): Position {
    return {
      ...position,
      employee: position.jobAssignments?.length > 0 && position.jobAssignments[0].employee ? MappingHelpers.mapEmployee(position.jobAssignments[0].employee) : undefined
    };
  }
}
