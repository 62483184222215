import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OpenSearchResponse, OpenSearchResult, SearchResultList } from '@coin/modules/group-search/util';
import { environment } from '@coin/shared/util-environments';
import { Observable, map } from 'rxjs';
import { EmployeeSearchResult } from './employee-search-result.model';
import { LegalEntitySearchResult } from './legal-entity-search-result.model';
import { OrganisationSearchResult } from './organisation-search-result.model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(private http: HttpClient) {}

  public getOrganisations = (searchString: string, startFrom = 0): Observable<SearchResultList> => {
    return this.http
      .get<OpenSearchResponse>(
        `${environment.api.cosmosSearchService}search/organizations?q=*:*${encodeURIComponent(searchString)}*&default_operator=AND&sort=level:asc&from=${startFrom}`
      )
      .pipe(
        map(openSearchResult => {
          return {
            count: openSearchResult?.hits?.total?.value ?? 0,
            countRelation: openSearchResult?.hits?.total?.relation,
            results: this.mapToOrganisationSearchResults(openSearchResult?.hits?.hits)
          };
        })
      );
  };

  public getEmployees = (searchString: string, startFrom = 0): Observable<SearchResultList> => {
    return this.http
      .get<OpenSearchResponse>(
        `${environment.api.cosmosSearchService}search/employees?q=*:*${encodeURIComponent(searchString)}*+isActive:true&default_operator=AND&from=${startFrom}`
      )
      .pipe(
        map(openSearchResult => {
          return {
            count: openSearchResult?.hits?.total?.value ?? 0,
            countRelation: openSearchResult?.hits?.total?.relation,
            results: this.mapToEmployeeSearchResults(openSearchResult?.hits.hits)
          };
        })
      );
  };

  public getLegalEntities = (searchString: string, startFrom = 0): Observable<SearchResultList> => {
    return this.http
      .get<OpenSearchResponse>(`${environment.api.cosmosSearchService}search/legalentities?q=*:*${encodeURIComponent(searchString)}*&default_operator=AND&from=${startFrom}`)
      .pipe(
        map(openSearchResult => {
          return {
            count: openSearchResult?.hits?.total?.value ?? 0,
            countRelation: openSearchResult?.hits?.total?.relation,
            results: this.mapToLegalEntitySearchResults(openSearchResult?.hits.hits)
          };
        })
      );
  };

  private mapToOrganisationSearchResults(openSearchResults: OpenSearchResult[]): OrganisationSearchResult[] {
    if (!openSearchResults || openSearchResults.length === 0) return [];

    return openSearchResults.map(
      openSearchResult =>
        new OrganisationSearchResult({
          id: openSearchResult._id,
          orgName: openSearchResult._source.orgName,
          orgCode: openSearchResult._source.orgCode
        })
    );
  }

  private mapToEmployeeSearchResults(openSearchResults: OpenSearchResult[]): EmployeeSearchResult[] {
    if (!openSearchResults || openSearchResults.length === 0) return [];

    return openSearchResults.map(
      openSearchResult =>
        new EmployeeSearchResult({
          id: openSearchResult._id,
          gid: openSearchResult._source.gid,
          firstname: openSearchResult._source.firstName,
          lastname: openSearchResult._source.lastName,
          middleName: openSearchResult._source.middleName,
          lastNamePrefix: openSearchResult._source.lastNamePrefix,
          title: openSearchResult._source.title,
          primaryOrgCode: openSearchResult._source.orgCode,
          primaryOrgId: openSearchResult._source.orgCodeId,
          are: openSearchResult._source.are,
          location: openSearchResult._source.location,
          locationCity: openSearchResult._source.locationCity
        })
    );
  }

  private mapToLegalEntitySearchResults(openSearchResults: OpenSearchResult[]): LegalEntitySearchResult[] {
    if (!openSearchResults || openSearchResults.length === 0) return [];

    return openSearchResults.map(openSearchResult => new LegalEntitySearchResult(openSearchResult._source));
  }
}
