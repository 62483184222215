import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { AuthComponentsModule } from '@coin/modules/auth/feature';
import { SpecialRoleBoxMenuItem } from '@coin/modules/org-tree/util';
import { SpecialRole } from '@coin/shared/util-models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'coin-org-tree-special-role-box',
  templateUrl: './special-role-box.component.html',
  styleUrls: ['./special-role-box.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AuthComponentsModule, MatIconModule, TranslateModule, MatMenuModule]
})
export class OrgTreeSpecialRoleBoxComponent {
  @Input({ required: true }) specialRole: SpecialRole;
  @Input() specialRoleBoxActions: SpecialRoleBoxMenuItem[];
  @Input() isSelected = false;

  @Output() selected = new EventEmitter<SpecialRole>();
}
