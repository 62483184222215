import { Params, QueryParamsHandling } from '@angular/router';
import { SearchResultType } from '../enums/search-result-type.enum';

export abstract class SearchResult {
  abstract resultType: SearchResultType;
  abstract id: string;

  abstract get displayName(): string;
  abstract get routerLink(): string[];
  abstract get queryParams(): Params;

  public queryParamsHandling: QueryParamsHandling = '';

  public constructor(result: unknown) {
    Object.assign(this, result);
  }
}
