import { DestroyRef, Injectable, Type } from '@angular/core';
import { audit, BehaviorSubject, EMPTY, firstValueFrom, from, interval, Observable, startWith, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { ConfirmationDialogConfirm } from '@coin/shared/util-models';
import { AuthService, EmulationService, GeneralUserService } from '@coin/modules/auth/data-access';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const MINUTE = 60;
const HOUR = MINUTE * 60;

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  public remainingTime$: BehaviorSubject<number> = new BehaviorSubject(null);
  public tokenChecker$: Subscription;

  public isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public remainingTimeText$: Observable<string>;
  public timeWarningStyle$: Observable<'ok' | 'alarming' | 'urgent'>;

  constructor(
    private toast: ToastrService,
    private translate: TranslateService,
    private authService: AuthService,
    private dialog: MatDialog,
    private userService: GeneralUserService,
    private emulationService: EmulationService,
    private destroyRef: DestroyRef
  ) {}

  confirmationDialogComponent: Type<unknown>; // reference to class for dialog
  public init(confirmationDialogComponent: Type<unknown>): void {
    if (this.confirmationDialogComponent) return;
    this.confirmationDialogComponent = confirmationDialogComponent;

    this.remainingTime$
      .pipe(
        map(remainingTime => remainingTime > 0 || remainingTime === null),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(isLoggedIn => this.isLoggedIn$.next(isLoggedIn));

    this.remainingTimeText$ = this.remainingTime$.pipe(
      map(remainingTime => this.secondsToTimeText(remainingTime)),
      distinctUntilChanged()
    );

    this.timeWarningStyle$ = this.remainingTime$.pipe(
      map(remainingTime => {
        if (remainingTime < MINUTE) {
          return 'urgent';
        }
        if (remainingTime < 5 * MINUTE) {
          return 'alarming';
        }
        return 'ok';
      }),
      distinctUntilChanged<'ok' | 'alarming' | 'urgent'>()
    );

    this.showTokenNotifications();
  }

  public startCheckingTimeTillLogout(): void {
    if (this.tokenChecker$) return;

    this.tokenChecker$ = interval(1000)
      .pipe(
        startWith(null),
        map(() => this.authService.getRemainingTime()),
        tap(remainingTime => this.remainingTime$.next(remainingTime)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.authService
      .listenOnTokenExpiration()
      .pipe(
        audit(() =>
          this.openExpirationDialog(this.confirmationDialogComponent, {
            disableClose: true,
            data: {
              headline: 'general.session-info',
              msg: 'general.session-has-ended',
              confirmMsg: 'general.relogin',
              translate: true
            }
          })
        ),
        switchMap(() => from(this.renewToken())),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private showTokenNotifications(): void {
    this.remainingTime$
      .pipe(
        map(remainingTime => Math.round(remainingTime)),
        distinctUntilChanged(),
        filter(remainingTime => remainingTime === 5 * MINUTE || remainingTime === MINUTE),
        switchMap(remainingTime =>
          this.openExpirationDialog(this.confirmationDialogComponent, {
            data: {
              headline: 'general.session-info',
              msg: `${this.translate.instant('general.session-ends-in')} ${this.secondsToTimeText(remainingTime)}.`,
              confirmMsg: 'general.renew-token',
              cancelMsg: 'general.btnCancel',
              translate: true
            }
          })
        ),
        switchMap(result => (result ? from(this.renewToken()) : EMPTY)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private secondsToTimeText(seconds: number): string {
    if (seconds === undefined) {
      return '';
    }
    if (seconds > HOUR) {
      return `${Math.round(seconds / HOUR)} h`;
    }
    if (seconds > 5 * MINUTE) {
      return `${Math.round(seconds / MINUTE)} min`;
    }
    return `${Math.round(seconds)} sec`;
  }

  public async renewToken(): Promise<void> {
    const isEmulated = await this.authService.getIsEmulated();

    if (isEmulated) {
      const authType = (await this.authService.getEmulationDecoded())?.IsDeputy ? 'Deputy' : 'Emulation';
      await firstValueFrom(
        this.userService.getUserByToken().pipe(
          switchMap(user => this.emulationService.emulateEmployee(user.principal.id, authType)),
          catchError(() => from(this.reLogin()))
        )
      );
    } else {
      try {
        await this.authService.refreshToken();
        this.toast.success(this.translate.instant('general.token-renew-success'));
        this.startCheckingTimeTillLogout();
      } catch {
        await this.reLogin();
      }
    }
  }

  private reLogin(): Promise<void> {
    return this.authService.login();
  }

  private openExpirationDialog(...args: Parameters<MatDialog['open']>): Observable<ConfirmationDialogConfirm> {
    const expirationDialogId = 'token-expiration';
    args[1].id = expirationDialogId;
    const openDialog = (): Observable<ConfirmationDialogConfirm> => this.dialog.open(...args).afterClosed();

    const existingDialog = this.dialog.getDialogById(expirationDialogId);
    if (existingDialog) {
      existingDialog?.close();
      return existingDialog.afterClosed().pipe(switchMap(() => openDialog()));
    }

    return openDialog();
  }
}
