import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'coin-org-tree-employee-profile-manager-flag',
  templateUrl: './manager-flag.component.html',
  styleUrl: './manager-flag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, MatIconModule, MatTooltipModule]
})
export class ManagerFlagComponent {
  @Input({ required: true }) isManagerOverwritten: boolean;
}
