<mat-checkbox
  [class.indeterminate]="indeterminate"
  [indeterminate]="indeterminate"
  [color]="color"
  class="color-{{ color }}"
  [class.contrast]="contrast"
  [(ngModel)]="value"
  [disabled]="disabled"
  (click)="onTouch(value)"
  [name]="name"
  >{{ label }}</mat-checkbox
>
