import { Directive, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[preventRightClick]',
  standalone: true
})
export class PreventRightClickDirective {
  @HostListener('contextmenu', ['$event'])
  public onRightClick(e: MouseEvent): void {
    e.preventDefault();
  }
}
