import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RoutesRecognized } from '@angular/router';
import { GroupSearchBarComponent } from '@coin/modules/group-search/feature';
import { SearchResultGroup } from '@coin/modules/group-search/util';
import { environment } from '@coin/shared/util-environments';
import { GlobalEventsService } from '@coin/shared/util-helpers';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';
import { EmployeeSearchResultGroup } from '../../search/employee-search-result-group.model';
import { LegalEntitySearchResultGroup } from '../../search/legal-entity-search-result-group.model';
import { OrganisationSearchResultGroup } from '../../search/organisation-search-result-group';
import { SearchService } from '../../search/search.service';

@Component({
  selector: 'cosmos-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @ViewChild('searchContainer') public searchContainer: ElementRef;
  @ViewChild('searchBar') public searchBar: GroupSearchBarComponent;

  @Input() public isSidebarVisible: boolean;

  public searchResultGroups: SearchResultGroup[] = [
    new OrganisationSearchResultGroup(this.searchService.getOrganisations),
    new EmployeeSearchResultGroup(this.searchService.getEmployees),
    new LegalEntitySearchResultGroup(this.searchService.getLegalEntities)
  ];

  public isSearchBarVisibleOnMobile$ = new BehaviorSubject<boolean>(false);

  public isSearchVisible$: Observable<boolean>;

  public get currentEnvironment(): typeof environment {
    return environment;
  }

  constructor(
    private router: Router,
    private events: GlobalEventsService,
    private searchService: SearchService,
    private destroyRef: DestroyRef
  ) {}

  public ngOnInit(): void {
    this.isSearchVisible$ = this.router.events.pipe(
      filter(event => event instanceof RoutesRecognized),
      map(event => {
        const isVisible = (event as RoutesRecognized).state?.root?.firstChild?.data?.isHeaderSearchBarVisible;
        return isVisible == null || isVisible;
      })
    );

    this.events
      .listen('click')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(event => this.clickout(event));
  }

  public toggleSearch(): void {
    if (this.isSearchBarVisibleOnMobile$.value) {
      this.isSearchBarVisibleOnMobile$.next(false);
    } else {
      this.isSearchBarVisibleOnMobile$.next(true);

      setTimeout(() => {
        this.searchBar.focusInput();
      }, 0);
    }
  }

  private clickout = (event: MouseEvent): void => {
    const clickedOutside = !this.searchContainer?.nativeElement.contains(event.target);

    if (clickedOutside) {
      this.isSearchBarVisibleOnMobile$.next(false);
    }
  };
}
