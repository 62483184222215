import { DestroyRef, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PermissionResource } from '@coin/shared/util-enums';
import { EmployeeDto } from '@coin/shared/util-models';
import { take } from 'rxjs';
import { AuthService, EmulationService as SharedEmulationService } from '@coin/modules/auth/data-access';
import { SelectEmulatePersonComponent } from '@coin/modules/auth/feature';
import { PermissionsService } from '@coin/modules/auth/data-management';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class EmulationService {
  public isEmulating = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private emulationService: SharedEmulationService,
    private dialog: MatDialog,
    private permissionsService: PermissionsService,
    private destroyRef: DestroyRef
  ) {
    this.initializeIsEmulating();
  }

  public isEmulationPermitted(): boolean {
    return this.permissionsService.hasPermissions(PermissionResource.Emulation);
  }

  public selectEmulationUser(): void {
    this.dialog
      .open(SelectEmulatePersonComponent, { maxWidth: '820px', width: '70vw', height: '90vh', minHeight: '400px' })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(emulateUser => {
        if (emulateUser) {
          this.emulateEmployee(emulateUser);
        }
      });
  }

  public emulateEmployee(employee: EmployeeDto): void {
    if (!employee) return;

    this.emulationService
      .emulateEmployee(employee.id, 'Emulation')
      .pipe(take(1))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() =>
        setTimeout(() => {
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        }, 100)
      );
  }

  public removeEmulation(): void {
    this.authService.clearEmulation();
    setTimeout(() => {
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    }, 300);
  }

  private async initializeIsEmulating(): Promise<void> {
    this.isEmulating = !!(await this.authService.getEmulation());
  }
}
