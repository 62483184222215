// eslint-disable-next-line
import { environment as devEnvironment } from '../../../../libs/shared/util-environments/src/environment.dev';

export const environment = {
  ...devEnvironment,
  api: {
    ...devEnvironment.api,
    baseUrl: `${devEnvironment.api.baseUrl}/cosmosapi`
  }
};
