import { Params } from '@angular/router';
import { SearchResult, SearchResultType } from '@coin/modules/group-search/util';
import { NamePipe } from '../shared/pipes/name.pipe';

export class EmployeeSearchResult extends SearchResult {
  public resultType: SearchResultType = 'people';
  public id: string;
  public gid: string;
  public firstname: string;
  public lastname: string;
  public middleName: string;
  public lastNamePrefix: string;
  public title: string;
  public primaryOrgCode: string;
  public primaryOrgId: string;
  public are: string;
  public location: string;
  public locationCity: string;

  constructor(assignment: Partial<EmployeeSearchResult>) {
    super(assignment);
  }

  public get displayName(): string {
    return this.are ? `${this.getEmployeeName()} (${this.primaryOrgCode} / ${this.are.toUpperCase()})` : `${this.getEmployeeName()} (${this.primaryOrgCode})`;
  }

  public get routerLink(): string[] {
    return ['/org-management'];
  }

  public get queryParams(): Params {
    return { orgCode: this.primaryOrgCode, employeeGid: this.gid, employeeOrgCode: this.primaryOrgCode };
  }

  private getEmployeeName(): string {
    return NamePipe.formatEmployee({
      firstname: this.firstname,
      lastname: this.lastname,
      lastnamePrefix: this.lastNamePrefix,
      middlename: this.middleName,
      title: this.title
    });
  }
}
