import { Params } from '@angular/router';
import { SearchResult, SearchResultType } from '@coin/modules/group-search/util';

export class LegalEntitySearchResult extends SearchResult {
  public resultType: SearchResultType = 'legalEntities';
  public id: string;
  public are: string;
  public longName;
  public shortName: string;
  public gkz: string;

  constructor(assignment: Partial<LegalEntitySearchResult>) {
    super(assignment);
  }

  public get displayName(): string {
    return `${this.longName} (${this.are?.toUpperCase()})`;
  }

  public get routerLink(): string[] {
    return ['/legal-entities'];
  }

  public get queryParams(): Params {
    return { are: this.are };
  }
}
