import { UserAuthReq } from '@coin/shared/util-models';

export class SetDynamicMercerAccess {
  static readonly type = '[User] Set Dynamic Mercer Access';
  constructor(public readonly hasAccess: boolean) {}
}

export class ResetUser {
  static readonly type = '[User] Reset User';
}

export class LoadUser {
  static readonly type = '[User] Load User';
  constructor(public readonly useAsLoggedInUser = false) {}
}

export class LoadLoggedInUser {
  static readonly type = '[User] Load Logged In User';
}

export class LoadLoggedInUserSuccess {
  static readonly type = '[User] Load Logged In User Success';
  constructor(public readonly payload: UserAuthReq) {}
}

export class LoadUserSuccess {
  static readonly type = '[User] Load User Success';
  constructor(
    public readonly payload: UserAuthReq,
    public readonly useAsLoggedInUser = false
  ) {}
}
