<div class="container">
  <button
    (click)="toggle.emit()"
    class="no-style title-bar"
    [class.content-visible]="isContentVisible"
    [attr.aria-label]="(isContentVisible ? 'side-menu.menu-item.close' : 'side-menu.menu-item.open') | translate">
    <h3>{{ 'side-menu.' + header | translate }}</h3>
    <mat-icon [@toggleIcon]="isContentVisible ? 'open' : 'closed'" class="toggle-icon">keyboard_arrow_down</mat-icon>
  </button>
  @if (isContentVisible) {
    <div class="content">
      <ng-content></ng-content>
    </div>
  }
</div>
