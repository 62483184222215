import { DestroyRef, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@coin/shared/feature-legacy-components';
import { ContentLanguage, FrontendType, StorageKey } from '@coin/shared/util-enums';
import { LanguageMapping, UserMenuItem } from '@coin/shared/util-models';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { CmsTranslationService, StorageService } from '@coin/shared/data-access';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class LanguageMenuService {
  private selectableLanguages: LanguageMapping = undefined;

  public selectedLanguage: ContentLanguage;

  constructor(
    private cmsTranslationService: CmsTranslationService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private storageService: StorageService,
    private destroyRef: DestroyRef
  ) {
    this.initializeSelectedLanguage();
    this.initializeSelectableLanguages();
  }

  public getSelectableLanguageMenuItems(): UserMenuItem[] {
    return Object.entries(this.selectableLanguages)
      .filter(([_, value]) => !!value && value !== this.selectedLanguage)
      .map(([_, value]) => {
        return {
          name: `header.user-menu.languages.${value}`,
          action: () => this.selectLanguage(value)
        };
      });
  }

  private selectLanguage(language: string): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          headline: 'general.info',
          translate: true,
          msg: `general.may-need-reload-for-changes`,
          confirmMsg: 'general.btnProceed',
          cancelMsg: 'general.btnCancel'
        }
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        if (result) {
          this.translateService.use(language);
        }
      });
  }

  private async initializeSelectableLanguages(): Promise<void> {
    this.selectableLanguages = await firstValueFrom(this.cmsTranslationService.getTranslationConfigFile(FrontendType.cosmos));
  }

  private async initializeSelectedLanguage(): Promise<void> {
    this.selectedLanguage = this.storageService.get(StorageKey.LANGUAGE);

    this.translateService.onLangChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(item => {
      this.storageService.set(StorageKey.LANGUAGE, item.lang as ContentLanguage);
      this.selectedLanguage = item.lang as ContentLanguage;
    });
  }
}
