import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
  standalone: true
})
export class HighlightPipe implements PipeTransform {
  public transform(text: string, highlightValue: string): string {
    if (!highlightValue) {
      return text;
    }

    const regex = new RegExp(highlightValue, 'gi');

    return text.replace(regex, match => `<span class="highlight">${match}</span>`);
  }
}
