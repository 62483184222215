<div [class.invalid]="!!errorMessage" [matTooltip]="errorMessage" class="lib-input">
  @if (preIcon) {
    <span class="material-icons pre-icon">{{ preIcon }}</span>
  }
  @if (preSymbol) {
    <span class="pre-symbol">{{ preSymbol }}</span>
  }
  <mat-form-field
    class="lib-input coin-form-field-outline {{ addClass ? 'coin-form-field-outline--' + addClass : '' }} {{ noMargin ? 'no-margin' : '' }}"
    [ngClass]="inputIconClasses"
    appearance="outline"
    floatLabel="always">
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }
    @if (!isNumber && !isInteger) {
      <input
        (keyup.enter)="onEnter()"
        (focus)="focusEnterLeave('enter')"
        (input)="onInput($event.target.value)"
        [ngStyle]="{ 'text-align': textAlign }"
        (focusout)="focusEnterLeave('leave')"
        [placeholder]="placeholder"
        matInput
        [readonly]="readonly"
        [(ngModel)]="value"
        [disabled]="disabled"
        [required]="required"
        autocomplete="off"
        #input />
    }
    @if (isNumber || isInteger) {
      <input
        (keyup.enter)="onEnter()"
        (focus)="focusEnterLeave('enter')"
        (input)="onInput($event.target.value)"
        [ngStyle]="{ 'text-align': textAlign }"
        (focusout)="focusEnterLeave('leave')"
        [placeholder]="placeholder"
        matInput
        [(ngModel)]="value"
        [readonly]="readonly"
        [disabled]="disabled"
        [required]="required"
        type="number"
        appTwoDecimalPlaces
        [class.hide-input-arrows]="hideInputArrows"
        [isDecimal]="!isInteger"
        [minValue]="minValue"
        [maxValue]="maxValue"
        [step]="isInteger ? 1 : 0.01"
        #input />
    }
    @if (svgIcon) {
      <div #svgicon class="svg-icon"></div>
    }
  </mat-form-field>
  @if (afterIcon) {
    <span class="material-icons after-icon" [ngClass]="{ presym: preSymbol }">{{ afterIcon }}</span>
  }
  @if (afterSymbol) {
    <span class="after-symbol">{{ afterSymbol }}</span>
  }
</div>
