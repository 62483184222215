import { TreeNode } from '../route-builder';

export const SuccessionManagementModule = {
  segment: 'succession-management',
  children: {
    SuccessionManagementComponent: {
      segment: '',
      title: 'succession-management.title',
      emptyPage: true,
      navigationRoot: true,
      children: {
        TalentPoolDatesComponent: {
          segment: 'dates',
          icon: 'show_chart',
          title: 'succession-management.talent-pools'
        }
      }
    }
  }
} as const satisfies TreeNode;
