export enum SeasonTypeEnum {
  IncentiveV2 = 'IncentiveV2',
  SOG = 'SOG',
  GBR = 'GBR',
  Merit = 'Merit',
  SpecialPayment = 'SpecialPayment', // Only used as generic SP season
  SpecialPaymentCash = 'SpecialPaymentCash',
  SpecialPaymentEquity = 'SpecialPaymentEquity',
  PositionEvaluation = 'PositionEvaluation',
  Unknown = 'Unknown',
  Restructuring = 'Restructuring'
}
