import { Injectable } from '@angular/core';
import { OrganisationNode } from '@coin/modules/org-tree/util';
import { Position, SpecialRole } from '@coin/shared/util-models';
import { ImmerComponentStore } from 'ngrx-immer/component-store';
import { Observable } from 'rxjs';

interface OrgTreeStateModel {
  organisationNodes: { [organisationId: string]: OrganisationNode };
  businessPartners: { [organisationId: string]: SpecialRole[] };
  specialResponsibilities: { [organisationId: string]: SpecialRole[] };
  positions: { [organisationId: string]: Position[] };
  positionsLoading: { [organisationId: string]: boolean };
  currentOrganisationId?: string;
  selectedOrganisationId?: string;
}

const initialState = (): OrgTreeStateModel => ({
  organisationNodes: {},
  businessPartners: {},
  specialResponsibilities: {},
  positions: {},
  positionsLoading: {}
});

@Injectable()
export class OrgTreeComponentState extends ImmerComponentStore<OrgTreeStateModel> {
  public currentOrganisationNode$: Observable<OrganisationNode> = this.select(({ currentOrganisationId, organisationNodes }) => organisationNodes[currentOrganisationId]);

  public currentOrganisationBusinessPartners$: Observable<SpecialRole[]> = this.select(({ currentOrganisationId, businessPartners }) => businessPartners[currentOrganisationId]);

  public currentOrganisationSpecialResponsibilities$: Observable<SpecialRole[]> = this.select(
    ({ currentOrganisationId, specialResponsibilities }) => specialResponsibilities[currentOrganisationId]
  );

  public currentOrganisationHasBusinessPartnersOrSpecialResponsibilities$: Observable<boolean> = this.select(
    this.currentOrganisationBusinessPartners$,
    this.currentOrganisationSpecialResponsibilities$,
    (businessPartners, specialResonsibilities) => businessPartners?.length > 0 || specialResonsibilities?.length > 0
  );

  public selectedOrganisationId$: Observable<string> = this.select(({ selectedOrganisationId }) => selectedOrganisationId);

  public selectedOrganisationNode$: Observable<OrganisationNode> = this.select(({ selectedOrganisationId, organisationNodes }) => organisationNodes[selectedOrganisationId]);

  public selectedOrganisationBusinessPartners$: Observable<SpecialRole[]> = this.select(({ selectedOrganisationId, businessPartners }) => businessPartners[selectedOrganisationId]);

  public selectedOrganisationSpecialResponsibilities$: Observable<SpecialRole[]> = this.select(
    ({ selectedOrganisationId, specialResponsibilities }) => specialResponsibilities[selectedOrganisationId]
  );

  public selectedOrganisationHasBusinessPartnersOrSpecialResponsibilities$: Observable<boolean> = this.select(
    this.selectedOrganisationBusinessPartners$,
    this.selectedOrganisationSpecialResponsibilities$,
    (businessPartners, specialResonsibilities) => businessPartners?.length > 0 || specialResonsibilities?.length > 0
  );

  public selectedOrganisationNodeHasChildren$: Observable<boolean> = this.select(
    this.selectedOrganisationNode$,
    this.selectedOrganisationHasBusinessPartnersOrSpecialResponsibilities$,
    (selectedOrganisationNode, hasSpecialRoles) => selectedOrganisationNode?.children?.length > 0 || hasSpecialRoles
  );

  constructor() {
    super(initialState());
  }

  public getOrganisationNode(organisationId: string): OrganisationNode {
    return this.get().organisationNodes[organisationId];
  }

  public setCurrentOrganisationId(organisationId: string): void {
    this.setState(state => {
      if (state.currentOrganisationId !== organisationId) {
        state.currentOrganisationId = organisationId;
      }
    });
  }

  public setOrganisationNodes(organisationNodes: { [organisationId: string]: OrganisationNode }): void {
    this.setState(state => {
      state.organisationNodes = organisationNodes;
    });
  }

  public setBusinessPartners(businessPartners: { [organisationId: string]: SpecialRole[] }): void {
    this.setState(state => {
      state.businessPartners = businessPartners;
    });
  }

  public setSpecialResponsibilities(specialResponsibilities: { [organisationId: string]: SpecialRole[] }): void {
    this.setState(state => {
      state.specialResponsibilities = specialResponsibilities;
    });
  }

  public setPositions(positions: { [organisationId: string]: Position[] }): void {
    this.setState(state => {
      state.positions = positions;
    });
  }

  public setPositionsLoading(positionsLoading: { [organisationId: string]: boolean }): void {
    this.setState(state => {
      state.positionsLoading = positionsLoading;
    });
  }

  public setSelectedOrganisationId(organisationId: string): void {
    this.setState(state => {
      if (state.selectedOrganisationId !== organisationId) {
        state.selectedOrganisationId = organisationId;
      }
    });
  }

  public selectPositions(organisationId: string): Observable<Position[]> {
    return this.select(({ positions }) => positions[organisationId]);
  }

  public selectPositionsLoading(organisationId: string): Observable<boolean> {
    return this.select(({ positionsLoading }) => positionsLoading[organisationId]);
  }
}
