import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { LoadingModule } from '@coin/shared/data-access';
import { HighlightPipe } from '@coin/modules/group-search/util';
import { ClickedOutsideDirective } from '@coin/shared/util-directives';
import { GroupSearchResultsComponent } from './components/search-results/group-search-results.component';
import { GroupSearchBarComponent } from './components/search-bar/group-search-bar.component';

@NgModule({
  declarations: [GroupSearchBarComponent, GroupSearchResultsComponent],
  exports: [GroupSearchBarComponent, GroupSearchResultsComponent],
  imports: [FormsModule, CommonModule, MatIconModule, TranslateModule.forChild(), RouterModule, LoadingModule, HighlightPipe, ClickedOutsideDirective]
})
export class GroupSearchModule {}
