import { TreeNode } from '../route-builder';

export const IncentiveSupportModule = {
  segment: 'incentive-support',
  children: {
    IncentiveSupportComponent: { segment: '' },
    IncentiveSupportReassignmentsComponent: { segment: 'reassignments' },
    IncentiveSupportMonitoringComponent: { segment: 'monitoring' }
  }
} as const satisfies TreeNode;
