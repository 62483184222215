<div class="faq-answer-items-holder">
  <h3 style="margin-bottom: 20px">{{ items?.title }}</h3>
  @for (item of items?.content; track item.title) {
    <div class="answer-items">
      @if (item.type === 'headline' && item.size === 'big') {
        <h3>{{ item.title }}</h3>
      }
      @if (item.type === 'headline' && item.size === 'small') {
        <h4 style="margin-bottom: 10px">
          {{ item.title }}
        </h4>
      }
      @if (item.type === 'text-block') {
        <p [innerHTML]="item.text"></p>
      }
      @if (item.type === 'button') {
        <coin-action-button class="{{ item.style }}" [icon]="item.icon" (click)="openPage(item['link'])">{{ item.text }}</coin-action-button>
      }
      @if (item.type === 'single-or-multi-image') {
        <div>
          <div class="single-double-main">
            @for (image of item?.content; track image.link) {
              <div class="flex-one">
                <div
                  class="single-double-image"
                  [ngClass]="{ 'multiple-images': item?.content?.length > 1 }"
                  [style.background-image]="'url(' + image.link + ')'"
                  [style.height.px]="item?.height"></div>
              </div>
            }
          </div>
        </div>
      }
      @if (item.type === 'table') {
        <div [innerHTML]="item.text"></div>
      }
      @if (item.type === 'seperator') {
        <div class="faq-seperator"></div>
      }
      @if (item.type === 'image') {
        <img aria-label="answer image" [src]="item.src && item.src !== '' ? item.src : 'assets/images/mac_skyline.png'" />
      }
      @if (item.type === 'list') {
        <ul>
          @for (listItem of item.items; track listItem) {
            <li [innerHTML]="listItem"></li>
          }
        </ul>
      }
    </div>
  }
</div>
