import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { catchError, Observable, switchMap } from 'rxjs';
import { produce } from 'immer';
import { HttpHelpersService } from '@coin/shared/data-access';
import { Position } from '@coin/shared/util-models';
import { EmployeeProfile } from '@coin/modules/org-tree/util';
import { HttpStatusCode } from '@angular/common/http';
import { EmployeePositionsService, EmployeeProfileService, EmployeeQuickProfile } from '../../../../api/generated';
import { LoadPositions, LoadEmployeeProfile, PositionsLoaded, EmployeeProfileLoaded } from './quickdraw-profile.actions';
import { MappingHelpers } from '../../../helper/mapping-helpers';
import { LoadingError } from '../../../../org-management/models/loading-error.enum';

export interface QuickdrawProfileStateModel {
  employeeProfileLoadingError: LoadingError;
  employeeProfileLoading: boolean;
  employeeProfiles: { [employeeId: string]: EmployeeProfile };
  positionsLoading: boolean;
  positions: { [employeeId: string]: Position[] };
}

const STATE_DEFAULTS: QuickdrawProfileStateModel = {
  employeeProfileLoadingError: undefined,
  employeeProfileLoading: false,
  employeeProfiles: {},
  positionsLoading: false,
  positions: {}
};

@State<QuickdrawProfileStateModel>({
  name: 'QuickdrawState',
  defaults: STATE_DEFAULTS
})
@Injectable()
export class QuickdrawProfileState {
  constructor(
    private employeeProfileService: EmployeeProfileService,
    private employeePositionsService: EmployeePositionsService,
    private httpHelper: HttpHelpersService
  ) {}

  @Selector()
  public static employeeProfileLoading(state: QuickdrawProfileStateModel): boolean {
    return state.employeeProfileLoading;
  }

  @Selector()
  public static positionsLoading(state: QuickdrawProfileStateModel): boolean {
    return state.positionsLoading;
  }

  @Selector()
  public static employeeProfileLoadingError(state: QuickdrawProfileStateModel): LoadingError {
    return state.employeeProfileLoadingError;
  }

  public static employeeProfile(employeeId: string): (state: QuickdrawProfileStateModel) => EmployeeProfile {
    return createSelector([QuickdrawProfileState], (state: QuickdrawProfileStateModel) => state.employeeProfiles[employeeId]);
  }

  public static employeePrimaryOrgId(employeeId: string): (state: QuickdrawProfileStateModel) => string {
    return createSelector([QuickdrawProfileState], (state: QuickdrawProfileStateModel) => state.employeeProfiles[employeeId]?.employee?.orgId);
  }

  public static positions(employeeId: string): (state: QuickdrawProfileStateModel) => Position[] {
    return createSelector([QuickdrawProfileState], (state: QuickdrawProfileStateModel) => state.positions[employeeId]);
  }

  public static otherEmployeePositions(employeeId: string, orgId: string): (state: QuickdrawProfileStateModel) => Position[] {
    return createSelector([QuickdrawProfileState], (state: QuickdrawProfileStateModel) =>
      orgId ? state.positions[employeeId]?.filter(pos => pos.organisationId !== orgId) : state.positions[employeeId]
    );
  }

  public static employeePosition(employeeId: string, orgId: string): (state: QuickdrawProfileStateModel) => Position {
    return createSelector([QuickdrawProfileState], (state: QuickdrawProfileStateModel) =>
      orgId ? state.positions[employeeId]?.find(pos => pos.organisationId === orgId) : undefined
    );
  }

  @Action(LoadEmployeeProfile)
  public loadEmployeeProfile(ctx: StateContext<QuickdrawProfileStateModel>, { employeeId }): Observable<void> {
    if (ctx.getState().employeeProfiles[employeeId]) {
      return;
    }

    ctx.patchState({ employeeProfileLoading: true, employeeProfileLoadingError: undefined });

    return this.employeeProfileService.getEmployeeProfile(employeeId).pipe(
      catchError(response => {
        const loadingError = response.status === HttpStatusCode.NotFound ? LoadingError.NotFound : LoadingError.Other;
        ctx.patchState({ employeeProfileLoading: false, employeeProfileLoadingError: loadingError });
        throw response;
      }),
      switchMap(quickProfile => ctx.dispatch(new EmployeeProfileLoaded(employeeId, quickProfile)))
    );
  }

  @Action(EmployeeProfileLoaded)
  public employeeProfileLoaded(ctx: StateContext<QuickdrawProfileStateModel>, { employeeId, quickProfile }): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.employeeProfileLoading = false;
        state.employeeProfileLoadingError = undefined;
        state.employeeProfiles[employeeId] = this.mapEmployeeProfile(quickProfile);
      })
    );
  }

  @Action(LoadPositions)
  public loadPositions(ctx: StateContext<QuickdrawProfileStateModel>, { employeeId }): Observable<void> {
    if (ctx.getState().positions[employeeId]) {
      return;
    }

    ctx.patchState({ positionsLoading: true });

    return this.employeePositionsService.getEmployeePositions(employeeId).pipe(
      this.httpHelper.handleError('Cannot get positions for employee'),
      switchMap(positions => ctx.dispatch(new PositionsLoaded(employeeId, positions)))
    );
  }

  @Action(PositionsLoaded)
  public positionsLoaded(ctx: StateContext<QuickdrawProfileStateModel>, { employeeId, positions }): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.positionsLoading = false;
        state.positions[employeeId] = positions.positions.map(MappingHelpers.mapPosition);
      })
    );
  }

  private mapEmployeeProfile(profile: EmployeeQuickProfile): EmployeeProfile {
    return {
      employee: {
        ...MappingHelpers.mapEmployee(profile?.employeeBaseData),
        orgId: profile?.orgId,
        orgCode: profile?.orgCode,
        orgName: profile?.orgName,
        orgClass: profile?.orgClass,
        orgType: profile?.orgType,
        areCode: profile?.areCode,
        costCenter: profile?.costCenter
      },
      contactInfo: {
        email: profile?.email,
        mobile: profile?.mobileNumber,
        landline: profile?.landlineNumber
      },
      locationInfo: {
        timezone: profile?.location?.timezone,
        locationOffice: profile?.location?.locationOffice,
        regionIsoCode: profile?.location?.regionIsoCode,
        countryIsoCode: profile?.location?.countryIsoCode,
        city: profile?.location?.city
      },
      inCompanyManager: MappingHelpers.mapEmployee(profile?.inCompanyManager),
      lineManager: MappingHelpers.mapEmployee(profile?.lineManager),
      legacyManager: MappingHelpers.mapEmployee(profile?.legacyManager)
    };
  }
}
