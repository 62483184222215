import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { AuthGuard } from '@coin/modules/auth/data-management';
import { AuthGmsClientCallbackComponent, LoginScreenComponent, LogoutScreenComponent } from '@coin/modules/auth/feature';
import { NoBackendScreenComponent } from '@coin/shared/feature-legacy-components';
import { FrontendType } from '@coin/shared/util-enums';
import { Cosmos } from '@coin/shared/util-helpers';

const routes: Routes = [
  {
    path: Cosmos.segment('HomeModule'),
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state)],
    data: {
      isHeaderSearchBarVisible: false,
      frontendType: FrontendType.cosmos
    },
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: Cosmos.segment('LogoutModule'),
    component: LogoutScreenComponent
  },
  {
    path: Cosmos.segment('LoginModule'),
    component: LoginScreenComponent
  },
  {
    path: Cosmos.segment('OrgManagementModule'),
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state)],
    data: {
      frontendType: FrontendType.cosmos
    },
    loadChildren: () => import('./org-management/org-management.module').then(m => m.OrgManagementModule)
  },
  {
    path: Cosmos.segment('LegalEntitiesModule'),
    canActivate: [AuthGuard],
    data: {
      frontendType: FrontendType.cosmos
    },
    loadChildren: () => import('./legal-entities/legal-entities.module').then(m => m.LegalEntitiesModule)
  },
  {
    path: 'auth/callback',
    component: AuthGmsClientCallbackComponent
  },
  {
    path: 'fallback',
    component: NoBackendScreenComponent
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
