import { SearchResultGroup, SearchResultType } from '@coin/modules/group-search/util';
import { OrganisationSearchResult } from './organisation-search-result.model';

export class OrganisationSearchResultGroup extends SearchResultGroup {
  public resultType: SearchResultType = 'organisations';
  public titleKey = 'search.results.organisations';
  public noResultsMessageKey = 'search.results.no-organisations-found';
  public hideResultsDescriptionKey = 'search.results.hide-organisations';
  public showResultsDescriptionKey = 'search.results.show-organisations';
  public icon?: string;
  public svgIcon? = 'org';
  public searchResultClass = OrganisationSearchResult;
}
