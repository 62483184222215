import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cosmos-collapsed-bar',
  templateUrl: './collapsed-bar.component.html',
  styleUrls: ['./collapsed-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapsedBarComponent {
  @Input()
  public titles: string[];

  @Output()
  public toggleItem = new EventEmitter<string>();

  openMenu(title: string): void {
    this.toggleItem.emit(title);
  }
}
