import { Pipe, PipeTransform } from '@angular/core';
import { SpecialRole } from '@coin/shared/util-models';

@Pipe({
  name: 'sortRoles',
  standalone: true
})
export class SortRolesPipe implements PipeTransform {
  transform(roles: SpecialRole[]): SpecialRole[] {
    return roles?.filter(role => !!role).sort(SortRolesPipe.compareRoles);
  }

  public static compareRoles(roleA: SpecialRole, roleB: SpecialRole): number {
    return roleA.title.localeCompare(roleB.title);
  }
}
