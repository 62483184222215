import { SearchResultGroup, SearchResultType } from '@coin/modules/group-search/util';
import { LegalEntitySearchResult } from './legal-entity-search-result.model';

export class LegalEntitySearchResultGroup extends SearchResultGroup {
  public resultType: SearchResultType = 'legalEntities';
  public titleKey = 'search.results.legal-entities';
  public noResultsMessageKey = 'search.results.no-legal-entities-found';
  public hideResultsDescriptionKey = 'search.results.hide-legal-entities';
  public showResultsDescriptionKey = 'search.results.show-legal-entites';
  public icon? = 'business';
  public svgIcon?: string;
  public searchResultClass = LegalEntitySearchResult;
}
