import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { Fn } from '@coin/shared/util-models';
import { v4 as uuid } from 'uuid';

/**
 * @deprecated Use `coin-v2-text-input` or `coin-v2-number-input` instead.
 */
@Component({
  selector: 'coin-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {
  @Input() preselect: unknown;
  @Input() isNumber: boolean;
  @Input() isInteger: boolean;
  @Input() addClass: string;
  @Input() preIcon: string;
  @Input() preSymbol: string;
  @Input() afterIcon: string;
  @Input() afterSymbol: string;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() placeholder = '';
  @Input() label: string;
  @Input() svgIcon: string;
  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() textAlign: 'left' | 'center' | 'right' = 'left';
  @Input() noMargin = false;
  @Input() errorMessage: string;
  @Input() hideInputArrows = false;
  @Input() readonly: boolean;

  @Output() inputNew = new EventEmitter<string>();
  @Output() enterClicked = new EventEmitter<number>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClear = new EventEmitter<void>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onBlur = new EventEmitter<void>();

  @ViewChild('svgicon') svgicon: ElementRef;
  @ViewChild('input', { static: false }) input: ElementRef;
  @ViewChild(MatTooltip) tooltip: MatTooltip;

  private rndNum: string = uuid();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private lottie: any;

  public inputIconClasses: Record<string, unknown>;

  private _value: string = null;

  public set value(val: string) {
    const oldValue = this._value;
    this._value = val;

    // use type agnostic comparison as _value might not match form-data
    // eslint-disable-next-line eqeqeq
    if (this._value != oldValue) this.onChange(val);
  }

  public get value(): string {
    return this._value;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    if (isPlatformBrowser(platformId)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.lottie = (window as any)?.lottie;
    }
  }

  onChange: Fn = () => {};
  onTouch: Fn = () => {};

  ngOnInit(): void {
    this.setIconClasses();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (changes.errorMessage?.currentValue) {
        this.tooltip?.show();
      } else {
        this.tooltip?.hide();
      }
    });
  }

  ngAfterViewInit(): void {
    if (!this.svgIcon) {
      return;
    }

    if (this.lottie?.loadAnimation) {
      this.lottie?.loadAnimation({
        container: this.svgicon.nativeElement, // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: false,
        name: this.rndNum,
        path: `assets/svgs/icon-data/${this.svgIcon}.json` // the path to the animation json
      });
      if (this.svgIcon === 'search') {
        this.lottie?.goToAndStop(27, true, this.rndNum);
      }
    }
  }

  private setIconClasses(): void {
    this.inputIconClasses = {
      'admin-form-field-outline--preIcon': this.preIcon || this.preSymbol,
      'admin-form-field-outline--afterIcon': this.afterIcon,
      'admin-form-field-outline--svgIcon': this.svgIcon
    };
  }

  public focusEnterLeave(type: 'enter' | 'leave'): void {
    if (type === 'leave') {
      this.onTouch();
      this.onBlur.emit();
    }
    if (!this.svgIcon) {
      return;
    }

    if (type === 'enter' && this.lottie?.play) {
      this.lottie?.play(this.rndNum);
    } else if (this.svgIcon === 'search' && this.lottie?.goToAndStop) {
      this.lottie?.goToAndStop(27, true, this.rndNum);
    } else if (this.lottie?.stop) {
      this.lottie?.stop(this.rndNum);
    }
  }

  public focus(): void {
    this.input.nativeElement.focus();
  }

  public onInput(e: string): void {
    this.inputNew.emit(e);
  }

  public clear(): void {
    this.writeValue('');
  }

  public onEnter(): void {
    this.enterClicked.emit();
  }

  public writeValue(value: string): void {
    this.value = value;
  }

  public registerOnChange(fn: Fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Fn): void {
    this.onTouch = fn;
  }
}
