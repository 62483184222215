import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SearchResultGroup } from '@coin/modules/group-search/util';
import { ClickedOutsideDirective } from '@coin/shared/util-directives';

@Component({
  selector: 'coin-group-search-bar',
  templateUrl: './group-search-bar.component.html',
  styleUrls: ['./group-search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    {
      directive: ClickedOutsideDirective,
      outputs: ['clickedOutside']
    }
  ]
})
export class GroupSearchBarComponent implements AfterViewInit {
  @ViewChild('searchInputElement') searchInputElement: ElementRef;

  @Input() public hasInitialFocus = false;
  @Input({ required: true }) searchResultGroups: SearchResultGroup[];
  @Input() resultCacheKey: string;
  @Input() placeholder = 'general.search-placeholder';
  @Output() initializeSearchLambda = new EventEmitter();

  public searchInput: string;

  public isSearchResultVisible$ = new BehaviorSubject<boolean>(false);

  @HostListener('focusin')
  public onFocusEnter(): void {
    if (!this.isSearchResultVisible$.value) {
      this.isSearchResultVisible$.next(true);
    }
  }

  @HostListener('clickedOutside')
  public handleClickedOutside() {
    this.isSearchResultVisible$.next(false);
  }

  @HostListener('document:keydown.escape')
  public onEscape(): void {
    if (this.isSearchResultVisible$.value) {
      this.isSearchResultVisible$.next(false);
      this.searchInputElement.nativeElement.blur();
    }
  }

  public ngAfterViewInit(): void {
    if (this.hasInitialFocus) {
      this.searchInputElement?.nativeElement?.focus();
    }
  }

  public onInputChange(value: string): void {
    this.searchInput = value;
  }

  public clearSearch(): void {
    this.searchInput = '';
  }

  public focusInput(): void {
    this.searchInputElement.nativeElement.focus();
  }

  public onNavigatedToResult(): void {
    this.onInputChange('');
    this.isSearchResultVisible$.next(false);
  }
}
