import { environment, environmentProd } from '@coin/shared/util-environments';
import { RouteBuilder, RouteSelector, RouteTree, TypedParams } from '../route-builder';
import { CmsModule } from './cms-module';
import { CommunicationsModule } from './communications-module';
import { EmploymentConditionsLibraryModule } from './employment-conditions-library-module';
import { HomePageModule } from './home-page-module';
import { IncentiveModule } from './incentive-module';
import { InterfacesModule } from './interfaces-module';
import { MasterDataModule } from './master-data-module';
import { OrgManagementModule } from './org-management-module';
import { OrgPlanningModule } from './org-planning-module';
import { SeasonMgmtModule } from './season-mgmt-module';
import { SuccessionManagementModule } from './succession-management-module';
import { TemplateLibraryModule } from './template-library-module';
import { TranslationsModule } from './translations-module';
import { UserMgmtModule } from './user-mgmt-module';
import { RestructuringModule, RestructuringSeasonModule } from './restructuring-module';

const routes = {
  HomePageModule,
  CmsModule,
  MasterDataModule,
  OrgPlanningModule,
  OrgManagementModule,
  SeasonMgmtModule,
  InterfacesModule,
  UserMgmtModule,
  CommunicationsModule,
  EmploymentConditionsLibraryModule,
  IncentiveModule,
  TranslationsModule,
  TemplateLibraryModule,
  RestructuringModule,
  RestructuringSeasonModule,
  SuccessionManagementModule
} satisfies RouteTree;
// TODO: workaround while localhost:8101 is unavailable
const host = environment.production ? environment.adminUrl : environment.customerUrl;
export const Admin = new RouteBuilder(host, routes);
export type AdminParams<T extends RouteSelector<(typeof Admin)['routes']>> = TypedParams<(typeof Admin)['routes'], T>;

export const ProdAdmin = new RouteBuilder(environmentProd.adminUrl, routes);
