import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';

import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DefaultControlValueAccessor } from '@coin/shared/util-helpers';

@Component({
  selector: 'coin-v2-checkbox',
  standalone: true,
  imports: [MatCheckboxModule, FormsModule],
  templateUrl: './v2-checkbox.component.html',
  styleUrls: ['./v2-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => V2CheckboxComponent),
      multi: true
    }
  ]
})
export class V2CheckboxComponent extends DefaultControlValueAccessor<boolean> {
  @Input() label: string;
  @Input() contrast = false;
  @Input() indeterminate = false;
  @Input() color: CheckboxColor = 'accent';
  @Input() name: string;

  public writeValue(value: boolean) {
    super.writeValue(castFromString(value));
  }
}

function castFromString(value: boolean | string): boolean {
  if (typeof value === 'string') return value === 'true';
  return value;
}

export const CHECKBOX_COLORS = ['accent', 'primary'] as const;
export type CheckboxColor = (typeof CHECKBOX_COLORS)[number];
