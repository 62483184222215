import { SearchResultGroup, SearchResultType } from '@coin/modules/group-search/util';
import { EmployeeSearchResult } from './employee-search-result.model';

export class EmployeeSearchResultGroup extends SearchResultGroup {
  public resultType: SearchResultType = 'people';
  public titleKey = 'search.results.employees';
  public noResultsMessageKey = 'search.results.no-employees-found';
  public hideResultsDescriptionKey = 'search.results.hide-employees';
  public showResultsDescriptionKey: 'search.results.show-organisations';
  public icon? = 'people';
  public svgIcon?: string;
  public searchResultClass = EmployeeSearchResult;
}
