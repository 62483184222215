import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@coin/modules/auth/data-access';
import { LoadLoggedInUser, LoadUser } from '@coin/modules/auth/util';
import { FrontendType, PermissionResource } from '@coin/shared/util-enums';
import { TinyHelpers } from '@coin/shared/util-helpers';
import { Store } from '@ngxs/store';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserState } from './user.state';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private store: Store,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this.authService.isE2eTest$.value) {
      while (!(await this.authService.getEmulation())) {
        await TinyHelpers.sleep(20);
      }
    }

    if (this.authService.isAuthenticated$.value) {
      const requests$ = this.authService.isE2eTest$.value
        ? [this.store.dispatch(new LoadUser(true))]
        : [this.store.dispatch(new LoadUser()), this.store.dispatch(new LoadLoggedInUser())];
      return forkJoin(requests$)
        .pipe(
          map(res => {
            if (!this.hasPermissions(route.data.frontendType ?? FrontendType.admin)) {
              this.router.navigate(['unauthorized']);
              return false;
            }
            return !!res;
          })
        )
        .toPromise();
    }
    this.authService.login(state.url);
    return false;
  }

  private hasPermissions(frontendType: FrontendType): boolean {
    const permissions = this.store.selectSnapshot(UserState.allPermissionsLoggedInUser);
    const frontendTypeResource: string = frontendType === FrontendType.admin ? PermissionResource.AdminApp : PermissionResource.CustomerApp;

    return permissions?.some(permission => [PermissionResource.All, frontendTypeResource].includes(permission.resource));
  }
}
