import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { addMinutes } from 'date-fns';
import { firstValueFrom } from 'rxjs';
import { saveAs } from 'file-saver';
import { GetImageWithGidService } from '@coin/modules/auth/data-access';
import { EmployeeProfile } from '@coin/modules/org-tree/util';
import { MicrosoftTeamsService, VcfService } from '@coin/shared/util-helpers';
import { ConfirmationDialogComponent } from '@coin/shared/feature-legacy-components';

@Component({
  selector: 'coin-org-tree-employee-profile-contact-button-bar',
  templateUrl: './contact-button-bar.component.html',
  styleUrls: ['./contact-button-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, MatIconModule, MatTooltipModule],
  providers: [VcfService, MicrosoftTeamsService]
})
export class ContactButtonBarComponent implements OnChanges {
  @Input({ required: true }) employeeProfile: EmployeeProfile;

  public hasValidMailAddress = false;

  constructor(
    private dialog: MatDialog,
    private imageService: GetImageWithGidService,
    private vcfService: VcfService,
    public msTeamsService: MicrosoftTeamsService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.employeeProfile) {
      this.hasValidMailAddress = this.isValidMailAddress(this.employeeProfile?.contactInfo?.email);
    }
  }

  public sendMail(): void {
    window.location.href = `mailto:${this.employeeProfile.contactInfo.email}`;
  }

  public async copyMail(event: MouseEvent): Promise<void> {
    event.preventDefault();
    await navigator.clipboard.writeText(this.employeeProfile.contactInfo.email);
    this.showEmailCopiedDialog(this.employeeProfile.contactInfo.email);
  }

  public async scheduleMeeting(): Promise<void> {
    const DEFAULT_START_DELAY = 60;
    const DEFAULT_DURATION = 30;
    const currentDate = new Date();

    this.msTeamsService.openMeetingDialog(
      `Meeting with ${this.employeeProfile.employee.firstname} ${this.employeeProfile.employee.lastname}`,
      addMinutes(currentDate, DEFAULT_START_DELAY),
      addMinutes(currentDate, DEFAULT_START_DELAY + DEFAULT_DURATION),
      [this.employeeProfile.contactInfo.email]
    );
  }

  public async downloadVcf(): Promise<void> {
    const imgSrc = await firstValueFrom(this.imageService.getImageWithStore(this.employeeProfile.employee.gid));

    const vCard = this.vcfService.generateVCard({
      firstname: this.employeeProfile.employee.firstname,
      lastname: this.employeeProfile.employee.lastname,
      middlename: this.employeeProfile.employee.middlename,
      orgCode: this.employeeProfile.employee.paOrgCode,
      email: this.employeeProfile.contactInfo.email,
      landlineNumber: this.employeeProfile.contactInfo.landline,
      mobileNumber: this.employeeProfile.contactInfo.mobile,
      imgData: imgSrc.slice(imgSrc.indexOf(',') + 1)
    });

    const blob = new Blob([vCard.toString()], { type: 'text/vcard' });
    saveAs(blob, `${this.employeeProfile.employee.firstname}_${this.employeeProfile.employee.lastname}.vcf`);
  }

  private showEmailCopiedDialog(email: string): void {
    this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        provideInput: true,
        inputReadOnly: true,
        currentInputValue: email,
        headline: 'employee-profile.actions.email-copied-header',
        translate: true,
        msg: `employee-profile.actions.email-copied-header-message`,
        confirmMsg: 'general.btnOk'
      }
    });
  }

  private isValidMailAddress(mailAddress: string): boolean {
    return !!mailAddress && mailAddress !== 'unknown@siemens.com';
  }
}
