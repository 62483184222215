<div class="segment left">
  @if (icon || svgIcon) {
    <mat-icon class="icon" [svgIcon]="svgIcon">{{ icon }}</mat-icon>
  }
  @if (contentLeft) {
    @if (!isTemplate(contentLeft)) {
      <p>{{ contentLeft }}</p>
    } @else {
      <ng-container *ngTemplateOutlet="contentLeft"></ng-container>
    }
  }
</div>
@if (textRight || textRight === 0) {
  <div class="segment right">
    <div class="text">
      @if (!isTemplate(textRight)) {
        <p coinEllipsisTooltip matTooltip>{{ textRight }}</p>
      } @else {
        <ng-container *ngTemplateOutlet="textRight"></ng-container>
      }
    </div>
  </div>
}
