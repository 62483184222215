@if (app) {
  <div class="coin-app" [class.disabled]="disabled">
    <div class="image-part empty-image">
      <span class="material-icons">{{ app.icon }}</span>
    </div>
    <div class="text-part">
      <p class="bold">{{ app.name | translate }}</p>
    </div>
  </div>
}
