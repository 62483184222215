import { Pipe, PipeTransform } from '@angular/core';
import { Organisation } from '@coin/shared/util-models';

@Pipe({
  name: 'groupOrganisations',
  standalone: true
})
export class GroupOrganisationsPipe implements PipeTransform {
  transform(nodes: Organisation[]): { [orgType: string]: Organisation[] } {
    return nodes
      .filter(org => !!org)
      .sort(GroupOrganisationsPipe.compareOrg)
      .reduce(
        (result, currentValue) => {
          (result[currentValue.orgType ?? ''] = result[currentValue.orgType ?? ''] || []).push(currentValue);
          return result;
        },
        {} as { [orgType: string]: Organisation[] }
      );
  }

  private static compareOrg(orgA: Organisation, orgB: Organisation): number {
    if ((orgA?.sortingOrder ?? '') === (orgB?.sortingOrder ?? '')) {
      return orgA?.orgCode.localeCompare(orgB?.orgCode);
    }

    if (!orgA?.sortingOrder) return 1;
    if (!orgB?.sortingOrder) return -1;

    return orgA.sortingOrder?.localeCompare(orgB.sortingOrder);
  }
}
