<div class="general-info" [class.selected]="isSelected">
  @if (specialRoleBoxActions?.length > 0) {
    <button
      (click)="$event.stopPropagation()"
      class="no-style menu-button"
      [matMenuTriggerFor]="specialRoleBoxMenu"
      [attr.aria-label]="'org-management.toggle-special-role-menu' | translate">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #specialRoleBoxMenu="matMenu">
      @for (menuItem of specialRoleBoxActions; track menuItem) {
        <button (click)="menuItem.action(specialRole)" mat-menu-item class="text">
          {{ menuItem.title | translate }}
        </button>
      }
    </mat-menu>
  }

  <button (click)="selected.emit(specialRole)" class="no-style" [attr.aria-label]="'org-management.show-employee-info' | translate">
    <div class="top-info">
      <div class="spacer"></div>
      <p class="bold">{{ specialRole.title }}</p>
      <div class="spacer" [class.more-space]="specialRoleBoxActions?.length > 0"></div>
    </div>
    <div class="bottom-info">
      <coin-siemens-energy-employee-preview
        [employee]="specialRole?.employee"
        [placeholderText]="'org-management.info.no-employee-assigned' | translate"
        [showFlag]="false"
        [boldName]="false"
        [showOrgCode]="false"
        [tiny]="true"></coin-siemens-energy-employee-preview>
    </div>
  </button>
</div>
