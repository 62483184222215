<button
  (mouseenter)="mouseEnterLeave('enter')"
  (mouseleave)="mouseEnterLeave('leave')"
  class="{{ iconClass }} {{ addClass }}"
  [style.background-color]="bgColor"
  [class.circle]="circle"
  [class.text-to-ellipsis]="ellipsis"
  [class.small]="small"
  [disabled]="disabled || loading"
  (click)="onClick($event)"
  #button>
  @if (arrowPos === 'left' && svgIcon) {
    <div
      #svgicon
      style="overflow: hidden"
      [ngStyle]="{
        width: iconTextDistance + 'px',
        'margin-right': iconTextMargin + 'px',
        height: height + 'px'
      }"
      (click)="onClick($event)"></div>
  }

  @if (matIcon && iconPos === 'left') {
    <mat-icon (click)="onClick($event)" [style.margin-right.px]="matIconTextMargin" [class.material-icons-outlined]="matIconOutlined">
      {{ matIcon }}
    </mat-icon>
  }

  <ng-content></ng-content>

  @if (matIcon && iconPos === 'right') {
    <mat-icon (click)="onClick($event)" [style.margin-left.px]="matIconTextMargin" [class.material-icons-outlined]="matIconOutlined">
      {{ matIcon }}
    </mat-icon>
  }

  @if (arrowPos === 'right' && svgIcon) {
    <div
      #svgicon
      style="overflow: hidden"
      [ngStyle]="{
        width: iconTextDistance + 'px',
        'margin-left': iconTextMargin + 'px',
        height: height + 'px'
      }"
      (click)="onClick($event)"></div>
  }

  @if (loading) {
    <div class="loading-container" (click)="onClick($event)">
      <app-turbine [contrast]="true" [withPole]="false"></app-turbine>
    </div>
  }
</button>
