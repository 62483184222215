import { Employee, User } from '../../../../../api/generated';

export class LoadUserImage {
  static readonly type = '[User Menu] Load User Image';
  constructor(public readonly user: User) {}
}

export class UserImageLoaded {
  static readonly type = '[User Menu] User Image loaded';
  constructor(public readonly imageSource: string) {}
}

export class LoadUserDetails {
  static readonly type = '[User Menu] Load User Details';
  constructor(public readonly user: User) {}
}

export class UserDetailsLoaded {
  static readonly type = '[User Menu] User Details loaded';
  constructor(public readonly userDetails: Employee) {}
}
