import { Params } from '@angular/router';
import { SearchResult, SearchResultType } from '@coin/modules/group-search/util';

export class OrganisationSearchResult extends SearchResult {
  public resultType: SearchResultType = 'organisations';
  public id: string;
  public orgName: string;
  public orgCode: string;

  constructor(assignment: Partial<OrganisationSearchResult>) {
    super(assignment);
  }

  public get displayName(): string {
    return `${this.orgCode} (${this.orgName})`;
  }

  public get routerLink(): string[] {
    return ['/org-management'];
  }

  public get queryParams(): Params {
    return { orgCode: this.orgCode };
  }
}
