<ngx-simplebar class="primary-scrollbar-vertical no-space modal-appearance">
  <div class="content" role="menu">
    <!-- Overview -->
    <button (click)="openApp('')" [matTooltip]="'main-menu.overview' | translate" [attr.aria-label]="'main-menu.overview' | translate" class="no-style coin-app" role="menuitem">
      <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.overview', icon: 'dashboard' }"></coin-siemens-energy-coin-app>
    </button>

    <!-- Allocation -->
    <button
      (click)="openApp('/budget-allocation')"
      [matTooltip]="'main-menu.allocation' | translate"
      [attr.aria-label]="'main-menu.allocation' | translate"
      permissionAccess
      [allowedResources]="[permissionResource.Allocation]"
      [useLoggedInUser]="true"
      class="no-style coin-app"
      role="menuitem">
      <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.allocation', icon: 'supervisor_account' }"></coin-siemens-energy-coin-app>
    </button>

    <!-- Support Apps -->
    <button
      (click)="openApp('/support-apps')"
      [matTooltip]="'main-menu.support-apps' | translate"
      [attr.aria-label]="'main-menu.support-apps' | translate"
      permissionAccess
      [allowedResources]="[
        permissionResource.AllocationHR,
        permissionResource.ReOrganisationApp,
        permissionResource.PositionEvaluationHR,
        permissionResource.OrganisationManagementAdmin,
        permissionResource.OrganisationPlanningAdmin,
        permissionResource.SuccessionManagementAdmin,
        permissionResource.SuccessionManagementHR,
        permissionResource.IncentiveSeasonHr
      ]"
      [useLoggedInUser]="true"
      class="no-style coin-app"
      role="menuitem">
      <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.support-apps', icon: 'recent_actors' }"></coin-siemens-energy-coin-app>
    </button>

    <!-- Special Payment -->
    <button
      (click)="openApp('/special-payment')"
      [matTooltip]="'main-menu.special-payment' | translate"
      [attr.aria-label]="'main-menu.special-payment' | translate"
      *permissions="{ allowedResources: [permissionResource.SpecialPayment], directAllowAccess: isLineManager }"
      class="no-style coin-app"
      role="menuitem">
      <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.special-payment', icon: 'diamond' }"></coin-siemens-energy-coin-app>
    </button>

    <!-- Mercer -->
    @if (isMercerAccessAllowed$ | async) {
      <button
        (click)="openApp('/mercer')"
        [matTooltip]="'main-menu.mercer' | translate"
        [attr.aria-label]="'main-menu.mercer' | translate"
        class="no-style coin-app"
        role="menuitem">
        <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.mercer', icon: 'account_tree' }"></coin-siemens-energy-coin-app>
      </button>
    }

    <!-- Organisation Planning -->
    <button
      (click)="openApp('/org-planning')"
      [matTooltip]="'main-menu.organisation-planning' | translate"
      [attr.aria-label]="'main-menu.organisation-planning' | translate"
      permissionAccess
      [allowedResources]="[permissionResource.OrganisationPlanning, permissionResource.OrganisationPlanningAdmin]"
      class="no-style coin-app"
      role="menuitem">
      <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.organisation-planning', icon: 'hub' }"></coin-siemens-energy-coin-app>
    </button>

    <!-- Organisation Management -->
    <ng-container *featureFlag="'orgManagement'">
      <button
        (click)="openApp('/org-management')"
        [matTooltip]="'main-menu.organisation-management' | translate"
        [attr.aria-label]="'main-menu.organisation-management' | translate"
        permissionAccess
        [allowedResources]="[permissionResource.OrganisationManagement, permissionResource.OrganisationManagementAdmin]"
        class="no-style coin-app"
        role="menuitem">
        <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.organisation-management', icon: 'corporate_fare' }"></coin-siemens-energy-coin-app>
      </button>
    </ng-container>

    <!-- Headcount Approval -->
    <ng-container *featureFlag="'headCountApprovalFeature'">
      <button
        (click)="openApp('/headcount-approval')"
        [matTooltip]="'main-menu.headcount-approval' | translate"
        [attr.aria-label]="'main-menu.headcount-approval' | translate"
        *permissions="{
          allowedResources: [permissionResource.CAndB, permissionResource.HeadcountApprovalHr, permissionResource.HeadcountApprovalAdmin],
          directAllowAccess: isLineManager
        }"
        class="no-style coin-app"
        role="menuitem">
        <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.headcount-approval', icon: 'badge' }"></coin-siemens-energy-coin-app>
      </button>
    </ng-container>

    <!-- ECL -->
    <button
      (click)="openApp('/ecl')"
      permissionAccess
      [allowedResources]="[
        permissionResource.EmploymentConditionLibraryUser,
        permissionResource.EmploymentConditionLibraryAdmin,
        permissionResource.EmploymentConditionLibraryRestricted,
        permissionResource.EmploymentConditionLibraryGenerator
      ]"
      [matTooltip]="'main-menu.ecl' | translate"
      [attr.aria-label]="'main-menu.ecl' | translate"
      class="no-style coin-app"
      role="menuitem">
      <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.ecl', icon: 'description' }"></coin-siemens-energy-coin-app>
    </button>

    <!-- My Profile -->
    @if (!isDeputy) {
      <button
        (click)="openApp('/employee-detail-overview')"
        [matTooltip]="'main-menu.my-profile' | translate"
        [attr.aria-label]="'main-menu.my-profile' | translate"
        class="no-style coin-app"
        role="menuitem">
        <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.my-profile', icon: 'person' }"></coin-siemens-energy-coin-app>
      </button>
    }

    <!-- Document Center -->
    <ng-container *featureFlag="'documentCenter'">
      @if (!isDeputy) {
        <button
          (click)="openApp('/document-center')"
          [matTooltip]="'main-menu.document-center' | translate"
          [attr.aria-label]="'main-menu.document-center' | translate"
          class="no-style coin-app"
          role="menuitem">
          <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.document-center', icon: 'how_to_vote' }"></coin-siemens-energy-coin-app>
        </button>
      }
    </ng-container>

    <!-- Analytics -->
    <button
      (click)="openPage(analyticsUrl)"
      [matTooltip]="'main-menu.analytics' | translate"
      [attr.aria-label]="'main-menu.analytics' | translate"
      permissionAccess
      [allowedResources]="[permissionResource.Analytics]"
      class="no-style coin-app"
      role="menuitem">
      <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.analytics', icon: 'analytics' }"> </coin-siemens-energy-coin-app>
    </button>

    <!-- Admin -->
    <button
      (click)="openAdminFrontend()"
      [matTooltip]="'main-menu.admin' | translate"
      [attr.aria-label]="'main-menu.admin' | translate"
      permissionAccess
      [allowedResources]="[permissionResource.AdminApp]"
      class="no-style coin-app"
      role="menuitem">
      <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.admin', icon: 'exit_to_app' }"></coin-siemens-energy-coin-app>
    </button>

    <!-- Equity -->
    <button (click)="openApp('/equity')" [matTooltip]="'main-menu.equity' | translate" [attr.aria-label]="'main-menu.equity' | translate" class="no-style coin-app" role="menuitem">
      <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.equity', icon: 'insights' }"></coin-siemens-energy-coin-app>
    </button>

    <!-- Incentive -->
    <button
      (click)="openApp('/incentive')"
      [matTooltip]="'main-menu.incentive' | translate"
      [attr.aria-label]="'main-menu.incentive' | translate"
      class="no-style coin-app"
      role="menuitem">
      <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.incentive', icon: 'timeline' }"></coin-siemens-energy-coin-app>
    </button>

    <!-- G2M -->
    <button (click)="openApp('/G2M')" [matTooltip]="'main-menu.g2m' | translate" [attr.aria-label]="'main-menu.g2m' | translate" class="no-style coin-app" role="menuitem">
      <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.g2m', icon: 'library_books' }"></coin-siemens-energy-coin-app>
    </button>

    <!-- Merit -->
    <button (click)="openApp('/merit')" [matTooltip]="'main-menu.merit' | translate" [attr.aria-label]="'main-menu.merit' | translate" class="no-style coin-app" role="menuitem">
      <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.merit', icon: 'bar_chart' }"></coin-siemens-energy-coin-app>
    </button>

    <!-- Star -->
    <button (click)="openApp('/star')" [matTooltip]="'main-menu.star' | translate" [attr.aria-label]="'main-menu.star' | translate" class="no-style coin-app" role="menuitem">
      <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.star', icon: 'star' }"></coin-siemens-energy-coin-app>
    </button>

    <!-- Succession Management -->
    <button
      *permissions="{ allowedResources: [permissionResource.All], directAllowAccess: isLineManager }"
      (click)="openApp('/succession-management')"
      [matTooltip]="'main-menu.succession-management' | translate"
      [attr.aria-label]="'main-menu.succession-management' | translate"
      class="no-style coin-app"
      role="menuitem">
      <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.succession-management', icon: 'switch_access_shortcut' }"></coin-siemens-energy-coin-app>
    </button>

    <!-- Restructuring -->
    <ng-container *featureFlag="'restructuring'">
      <button
        permissionAccess
        [allowedResources]="[permissionResource.RestructuringHr, permissionResource.RestructuringAdmin, permissionResource.RestructuringAdminRestricted]"
        (click)="openApp('/restructuring')"
        [matTooltip]="'main-menu.restructuring' | translate"
        [attr.aria-label]="'main-menu.restructuring' | translate"
        class="no-style coin-app"
        role="menuitem">
        <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.restructuring', icon: 'account_tree' }"></coin-siemens-energy-coin-app>
      </button>
    </ng-container>

    <ng-container>
      <button
        (click)="openApp('/', appType.cosmos)"
        [matTooltip]="'main-menu.cosmos' | translate"
        [attr.aria-label]="'main-menu.cosmos' | translate"
        class="no-style coin-app"
        role="menuitem">
        <coin-siemens-energy-coin-app [app]="{ name: 'main-menu.cosmos', icon: 'public' }"></coin-siemens-energy-coin-app>
      </button>
    </ng-container>
  </div>
</ngx-simplebar>
