import { Injectable } from '@angular/core';
import { GetImageWithGidService } from '@coin/modules/auth/data-access';
import { HttpHelpersService } from '@coin/shared/data-access';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import { firstValueFrom, Observable, switchMap } from 'rxjs';
import { CoinEmployee, Employee, EmployeesService } from '../../../../../api/generated';
import { LoadUserDetails, LoadUserImage, UserDetailsLoaded, UserImageLoaded } from './user-menu.actions';

export interface UserMenuStateModel {
  userImageSource: string;
  userDetails: Employee;
}

const STATE_DEFAULTS: UserMenuStateModel = {
  userImageSource: null,
  userDetails: undefined
};

@State<UserMenuStateModel>({
  name: 'UserMenuState',
  defaults: STATE_DEFAULTS
})
@Injectable()
export class UserMenuState {
  constructor(
    private httpHelper: HttpHelpersService,
    private getImageService: GetImageWithGidService,
    private employeeService: EmployeesService
  ) {}

  @Selector()
  public static userImageSource(state: UserMenuStateModel): string {
    return state.userImageSource;
  }

  @Selector()
  public static userDetails(state: UserMenuStateModel): Employee {
    return state.userDetails;
  }

  @Action(LoadUserImage)
  public loadUserImage(ctx: StateContext<UserMenuStateModel>, { user }): Observable<void> {
    return this.getImageService.getImageWithStore(user.gid).pipe(switchMap(imageSource => ctx.dispatch(new UserImageLoaded(imageSource))));
  }

  @Action(UserImageLoaded)
  public userImageLoaded(ctx: StateContext<UserMenuStateModel>, { imageSource }): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.userImageSource = imageSource;
      })
    );
  }

  @Action(LoadUserDetails)
  public async loadUserDetails(ctx: StateContext<UserMenuStateModel>, { user }): Promise<Observable<void>> {
    try {
      const employee = await firstValueFrom(this.employeeService.getEmployee(user.gid));
      return ctx.dispatch(new UserDetailsLoaded(this.mapEmployee(employee)));
    } catch (error) {
      return ctx.dispatch(new UserDetailsLoaded({ gid: user.gid, firstname: user.firstname, lastname: user.lastname }));
    }
  }

  @Action(UserDetailsLoaded)
  public userDetailsLoaded(ctx: StateContext<UserMenuStateModel>, { userDetails }): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.userDetails = userDetails;
      })
    );
  }

  private mapEmployee(coinEmployee: CoinEmployee): Employee {
    return {
      ...coinEmployee,
      firstname: coinEmployee.firstname,
      lastname: coinEmployee.lastname,
      lastnamePrefix: coinEmployee.surnamePrefix
    };
  }
}
