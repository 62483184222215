import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { FrontendType } from '@coin/shared/util-enums';
import { AuthGuard } from '@coin/modules/auth/data-management';
import { LoadingModule } from '@coin/shared/data-access';
import { OrgChartsSocCompatibilityComponent } from './components/org-charts-compatibility.component';

const routes: Routes = [
  {
    path: 'orgcharts/orgchart',
    canActivate: [AuthGuard],
    data: {
      frontendType: FrontendType.cosmos
    },
    component: OrgChartsSocCompatibilityComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), LegacyComponentsModule, LoadingModule],
  declarations: [OrgChartsSocCompatibilityComponent]
})
export class SocRoutingCompatibilityModule {}
