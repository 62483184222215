<div class="turbine" [class.contrast]="contrast" [class.no-shadow]="!shadow">
  <div class="pilot">
    <div class="prop-container prop-container--{{ speed }}">
      <div class="prop"></div>
      <div class="prop"></div>
      <div class="prop"></div>
    </div>
  </div>
  @if (withPole) {
    <div class="pole"></div>
  }
  @if (!reload() && text && currLoadingNum()) {
    <p @shrinkExpand class="turbine-text">Loading...</p>
  }
</div>
