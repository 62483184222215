let base = 'https://preprod.coin.siemens-energy.com';
if (typeof window !== 'undefined') {
  base = window.location.origin;
}

const apiBaseUrl = `${base}/ecs`;
const customerBaseUrl = 'https://preprod.coin.siemens-energy.com';
const adminBaseUrl = 'https://admin.preprod.coin.siemens-energy.com';
const cosmosBaseUrl = 'https://cosmos.preprod.coin.siemens-energy.com';
const importerBaseUrl = 'https://importer.preprod.coin.siemens-energy.com';

export const environment = {
  production: true,
  coinAssetsPath: 'https://d30qz857dkw45t.cloudfront.net',
  coinImagePath: 'https://d1ltwtrr1r0hn6.cloudfront.net/',
  authentication: {
    scopes: 'openid profile email',
    issuer: 'https://login.microsoftonline.com/common/v2.0/'
  },
  customerAuthentication: {
    callbackUrl: `${customerBaseUrl}/auth/callback`,
    clientId: '13d3841f-1c57-4069-be8a-36d3f759786b'
  },
  adminAuthentication: {
    callbackUrl: `${adminBaseUrl}/auth/callback`,
    clientId: '13d3841f-1c57-4069-be8a-36d3f759786b'
  },
  cosmosAuthentication: {
    callbackUrl: `${cosmosBaseUrl}/auth/callback`,
    clientId: '13d3841f-1c57-4069-be8a-36d3f759786b'
  },
  importerAuthentication: {
    enabled: true,
    callbackUrl: `${importerBaseUrl}/auth/callback`,
    clientId: '13d3841f-1c57-4069-be8a-36d3f759786b',
    tokenBlacklist: [
      'https://coin-help.s3.eu-central-1.amazonaws.com',
      'https://d1r-translations.s3.eu-central-1.amazonaws.com',
      'https://de0w05fuukbfv.cloudfront.net',
      'https://d149pn5xqxpybr.cloudfront.net',
      'https://d3eaunka2bmq7b.cloudfront.net',
      'https://coin-images.s3.eu-central-1.amazonaws.com',
      'https://d1r-translations.siemens.cloud',
      'https://experts-dev-dataimports.s3.eu-central-1.amazonaws.com',
      'https://experts-preprod-dataimports.s3.eu-central-1.amazonaws.com',
      'https://experts-prod-dataimports.s3.eu-central-1.amazonaws.com',
      'https://coin-download-documents.s3.eu-central-1.amazonaws.com',
      'https://coin-documents.s3.eu-central-1.amazonaws.com',
      'https://coin-generic-documents-dev.s3.eu-central-1.amazonaws.com',
      'https://coin-documents-zip-uploads.s3.eu-central-1.amazonaws.com',
      'http://localhost:8000',
      'https://coin-importer-jobs-service-uploads-dev.s3.eu-central-1.amazonaws.com',
      'https://d375j2sk9axnmo.cloudfront.net/',
      'https://d2kfj1rgt6hh2u.cloudfront.net/',
      'https://d2fq1hec2h85fe.cloudfront.net/',
      'https://prod.authproxy.siemens.cloud',
      'https://cognito-idp.eu-central-1.amazonaws.com'
    ]
  },
  baseButtonColorLight: false,
  api: {
    baseUrl: apiBaseUrl,
    letterServiceUrl: `${apiBaseUrl}/letterservice`,
    pdfCreationService: `${base}/api/serverless/pdf_creation_service`,
    documentsUrl: `${base}/api/serverless/document_service`,
    exportDownloadServiceUrl: `${base}/api/serverless/file_download_service`,
    imagesService: `${base}/api/serverless/image_service/`,
    cmsService: `${base}/api/serverless/cms_service/`,
    cmsServiceProd: 'https://coin.siemens-energy.com/api/serverless/cms_service/',
    cosmosSearchService: `${base}/api/serverless/search_service/`,
    importerService: `${base}/api/serverless/importer_service/api/v1`,
    importerApiKey: 'coin-importer-service-pre-prod',
    storageService: `${base}/api/serverless/storage_service`
  },
  device: 'desktop',
  cmsContentProdBucketCloudfront: 'https://cms.coin.siemens-energy.com/',
  cmsContentStageBucketCloudfront: 'https://cms.preprod.coin.siemens-energy.com/',
  documentDownloadCloudfront: 'https://d3uie4sj4d4ajd.cloudfront.net/',
  type: 'Pre-prod',
  setProfilePictureEndpoint: 'https://profile.hrs.siemens.cloud/',
  adminUrl: `${adminBaseUrl}/`,
  customerUrl: `${customerBaseUrl}/`,
  cosmosUrl: `${cosmosBaseUrl}/`,
  importerTableLimit: 25,
  subscriptionIntervalInMs: 5000,
  icon: 'verified',
  dataImportBucket: 'experts-preprod-dataimports',
  avatureJobUrl: 'http://siemensenergy.avature.net/internalcareers/FolderDetail/',
  helpdeskUrl: 'https://siemensenergy.service-now.com/sp?id=sc_cat_item&sys_id=29cfcd841bac061080ff62406b4bcbf1&referrer=popular_items'
};
