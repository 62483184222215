<div class="filter-container" [class.has-results]="searchString">
  <h2>{{ 'search.results.filter-title' | translate }}</h2>
  <div class="filter-list">
    @for (searchResultGroup of searchResultGroups; track searchResultGroup) {
      <button
        (click)="toggleFilter(searchResultGroup.resultType)"
        [attr.aria-label]="(isFilterActive(searchResultGroup.resultType) ? searchResultGroup.hideResultsDescriptionKey : searchResultGroup.showResultsDescriptionKey) | translate"
        class="no-style filter"
        [class.selected]="isFilterActive(searchResultGroup.resultType)">
        @if (searchResultGroup.icon) {
          <mat-icon>{{ searchResultGroup.icon }}</mat-icon>
        }
        @if (searchResultGroup.svgIcon) {
          <mat-icon [svgIcon]="searchResultGroup.svgIcon"></mat-icon>
        }
        {{ searchResultGroup.titleKey | translate }}
      </button>
    }
  </div>
</div>

@if (searchString) {
  <div>
    @for (searchResultGroup of searchResultGroups; track searchResultGroup) {
      @if (showSearchResults(searchResultGroup.resultType)) {
        <div class="search-group">
          <h3>
            {{ searchResultGroup.titleKey | translate }}
            {{
              ((searchResultGroup.isLoading$ | async) === false && searchResultGroup.hasResults$ | async) ? '(' + (searchResultGroup.resultsCountDisplayString$ | async) + ')' : ''
            }}
          </h3>

          @if ((searchResultGroup.hasResults$ | async) === false && (searchResultGroup.isLoading$ | async) === false) {
            <p class="bold">
              {{ searchResultGroup.noResultsMessageKey | translate }}
            </p>
          }

          @if (searchResultGroup.hasResults$ | async) {
            <ul>
              @for (result of searchResultGroup.results$ | async; track result) {
                <li>
                  <a (click)="navigateToResult(result, true)" [name]="searchResultGroup.titleKey" #searchResult>
                    <p [innerHTML]="result.displayName | highlight: searchString"></p
                  ></a>
                </li>
              }
            </ul>
          }

          @if (searchResultGroup.isLoading$ | async) {
            <app-loading-screen [contrast]="true" [local]="true"></app-loading-screen>
          }

          @if (searchResultGroup.hasMoreResults$ | async) {
            <button (click)="loadMoreResults(searchResultGroup.resultType)" class="no-style show-more">
              {{ 'search.results.show-more' | translate }}
            </button>
          }
        </div>
      }
    }
  </div>
} @else {
  @if (resultCacheKey && (recentSearchResults$ | async)?.length > 0) {
    <div class="search-group">
      <h3>{{ 'search.results.recent' | translate }}</h3>

      <ul>
        @for (result of recentSearchResults$ | async; track result) {
          <li>
            <a (click)="navigateToResult(result, false)"> <p [innerHTML]="result.displayName"></p></a>
          </li>
        }
      </ul>
    </div>
  }
}

<ng-template #recentSearchResults>
  @if (resultCacheKey && (recentSearchResults$ | async)?.length > 0) {
    <div class="search-group">
      <h3>{{ 'search.results.recent' | translate }}</h3>

      <ul>
        @for (result of recentSearchResults$ | async; track result) {
          <li>
            <a (click)="navigateToResult(result, false)"> <p [innerHTML]="result.displayName"></p></a>
          </li>
        }
      </ul>
    </div>
  }
</ng-template>
