<div class="search-bar" [class.show-results]="isSearchResultVisible$ | async" #searchBar>
  <mat-icon class="icon" aria-hidden="true">search</mat-icon>
  <input [(ngModel)]="searchInput" type="text" name="search" id="search" placeholder="{{ placeholder | translate }}" autocomplete="off" #searchInputElement />
  @if (searchInput) {
    <button class="no-style" [attr.aria-label]="'general.reset-search' | translate" (click)="clearSearch()"><mat-icon>close</mat-icon></button>
  }
</div>

<div [class.show]="(isSearchResultVisible$ | async) ? 1 : 0" class="results" #searchResults>
  <div class="scroll-container">
    <coin-group-search-results
      [resultCacheKey]="resultCacheKey"
      [searchResultGroups]="searchResultGroups"
      [searchString]="searchInput"
      (initializeSearchLambda)="initializeSearchLambda.emit()"
      (navigatedToResult)="onNavigatedToResult()"></coin-group-search-results>
  </div>
</div>
