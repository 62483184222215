import { trigger, state, style, transition, animate } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cosmos-sidebar-menu-item',
  templateUrl: './sidebar-menu-item.component.html',
  styleUrls: ['./sidebar-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('toggleIcon', [
      state('open', style({ transform: 'rotate(180deg)' })),
      state('closed', style({ transform: 'rotate(0)' })),
      transition('open => closed', [animate('450ms ease-in-out')]),
      transition('closed => open', [animate('450ms ease-in-out')])
    ])
  ]
})
export class SidebarMenuItemComponent {
  @Input()
  public header: string;

  @Input()
  public isContentVisible = false;

  @Output()
  public toggle = new EventEmitter();
}
