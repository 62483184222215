import { TrackByFunction, Type } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PaginatedResult } from '@coin/shared/util-models';
import { map, OperatorFunction } from 'rxjs';

export class TinyHelpers {
  static noWhitespaceValidator(control: FormControl<string>): null | { whitespace: true } {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  /** @deprecated This function is not reliable comparing the values, don't use */
  static areItemsSimilar<T>(object1: T, object2: T): boolean {
    return JSON.stringify(object1) === JSON.stringify(object2);
  }

  static escapeHtml(html: string): string {
    return html ? html.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;') : '';
  }

  static unescapeHtml(html: string): string {
    return html
      ? html
          .replace(/&amp;/g, '&')
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
          .replace(/&quot;/g, '"')
          .replace(/&#039;/g, `'`)
      : '';
  }

  static hasDuplicates(array: unknown[][], index: number): boolean {
    const values = array.map(item => item[index]);
    return values.some((value, index) => value && values.indexOf(value) !== index);
  }

  static scrolledOverVerticalBound(e: WheelEvent, prevScrollTop: number, amount = 0.9): boolean {
    const { scrollTop, scrollHeight, offsetHeight } = e.target as HTMLElement;
    const progressAmount = (scrollTop + offsetHeight - (prevScrollTop + offsetHeight)) / (scrollHeight - (prevScrollTop + offsetHeight));
    return progressAmount > amount;
  }

  static scrolledOverHorizontalBound(e: WheelEvent, prevScrollLeft: number, amount = 0.9): boolean {
    const { scrollLeft, scrollWidth, offsetWidth } = e.target as HTMLElement;
    const progressAmount = (scrollLeft + offsetWidth - (prevScrollLeft + offsetWidth)) / (scrollWidth - (prevScrollLeft + offsetWidth));
    return progressAmount > amount;
  }

  static isHorizontallyScrollable(element: HTMLElement): boolean {
    return element.scrollWidth > element.offsetWidth;
  }

  static isVerticallyScrollable(element: HTMLElement): boolean {
    return element.scrollHeight > element.offsetHeight;
  }

  static isString(value: unknown): value is string {
    return typeof value === 'string' || value instanceof String;
  }

  static convertDotToComma(text: string): string {
    return text?.replace(/[.]/g, ',');
  }

  static pascalcaseToText(text: string): string {
    return text?.replace(/([A-Z])/g, ' $1')?.trim();
  }

  static camelCaseToText(text: string): string {
    const result = text?.replace(/([A-Z])/g, ' $1');

    return result?.charAt(0).toUpperCase() + result.slice(1);
  }

  static camelOrPascalCaseToTitleText(text: string): string {
    const result = text?.replace(/([A-Z])/g, ' $1');

    return result?.replace(/^./, str => str.toUpperCase()).trim();
  }

  static camelCaseToPascalCase(text: string): string {
    return text?.replace(/([A-Z])/g, x => `-${x.toLowerCase()}`).trim();
  }

  static camelCaseToKebabCase(text: string): string {
    return text?.replace(/([A-Z])/g, x => `-${x.toLowerCase()}`).trim();
  }

  static pascalcaseToCamelCase(text: string): string {
    return text?.charAt(0).toLowerCase() + text?.slice(1);
  }

  static pascalcaseToKebabCase(text: string): string {
    return text
      ?.replace(/([A-Z])/g, '-$1')
      ?.slice(1)
      ?.toLowerCase()
      ?.trim();
  }

  static stringToCamelCase(string: string): string {
    return string
      .replace(/\s(.)/g, function ($1) {
        return $1.toUpperCase();
      })
      .replace(/\s/g, '')
      .replace(/^(.)/, function ($1) {
        return $1.toLowerCase();
      });
  }

  public static capitalize(value: string): string {
    return value.replace(/^\w/, m => m.toUpperCase());
  }

  public static uncapitalize(value: string): string {
    return value.replace(/^\w/, m => m.toLowerCase());
  }

  public static removeHtmlTags(text: string): string {
    return text.replace(/(<([^>]+)>)/gi, '');
  }

  public static lastItem<T>(array: T[]): T | undefined {
    return Array.isArray(array) ? array[array.length - 1] : undefined;
  }

  public static getOuterHeight(element: HTMLElement): number {
    const height = element.offsetHeight;
    const style = window.getComputedStyle(element);
    return ['top', 'bottom'].map(side => parseInt(style[`margin-${side}`])).reduce((total, side) => total + side, height);
  }

  public static sum(values: number[]): number {
    return values?.reduce((sum, value) => sum + value, 0);
  }

  public static getStartDayOfCurrentFiscalYear(): Date {
    const date = new Date();
    const lastYear = date.getMonth() < 9;
    date.setFullYear(date.getFullYear() - (lastYear ? 1 : 0), 9, 1);
    return date;
  }

  public static getEndDayOfCurrentFiscalYear(): Date {
    const date = this.getStartDayOfCurrentFiscalYear();
    date.setFullYear(date.getFullYear() + 1);
    date.setTime(date.getTime() - 24 * 60 * 60 * 1000);
    return date;
  }

  public static assert(condition: unknown): asserts condition {
    if (!condition) {
      throw new Error('assertion failed');
    }
  }

  public static isBetween(value: number, a: number, b: number): boolean {
    const min = Math.min(a, b);
    const max = Math.max(a, b);
    return value < max && value > min;
  }

  public static splitByBulletPoints(text: string): string[] {
    return text?.split(/(?=[•●])/);
  }

  public static deepUnstringify(item: string, its = 2): unknown {
    let iterations = its;
    let tempItem: unknown | string = item;
    while (tempItem && typeof tempItem === 'string' && iterations) {
      try {
        tempItem = JSON.parse(tempItem);
      } catch (error) {
        return null;
      }
      iterations--;
    }
    return tempItem;
  }

  public static distinct<T>(items: T[]): T[] {
    return items.filter((value, index, array) => array.indexOf(value) === index);
  }

  public static findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
    let l = array.length;
    while (l--) {
      if (predicate(array[l], l, array)) return l;
    }
    return -1;
  }

  public static getScrollBarWidth(): number {
    const scrollbox = document.createElement('div');
    scrollbox.style.overflow = 'scroll';
    document.body.appendChild(scrollbox);
    const width = scrollbox.offsetWidth - scrollbox.clientWidth;
    document.body.removeChild(scrollbox);
    return width;
  }

  public static range(minInclusive: number, maxInclusive: number): number[] {
    const length = maxInclusive - minInclusive + 1;
    return [...Array(length).keys()].map(num => num + minInclusive);
  }

  /** String.includes but case-insensitive */
  public static includesInsensitive(parent: string, subString: string): boolean {
    return parent.toLowerCase().includes(subString.toLowerCase());
  }

  static getEnumKeys(o: object): string[] {
    return Object.keys(o).filter(key => isNaN(Number(key)));
  }

  static getEnumKeyByValue(enumObj: unknown, value: string): string | null {
    return Object.keys(enumObj).find(key => enumObj[key] === value) || null;
  }

  /**
    @deprecated Only used as temporary workaround because some code
    tries to clone momentJS Objects, which throws. Use `structuredClone` if possible.
  */
  static nonThrowableStructuredCloneWorkaround<T>(toBeCloned: T): T {
    try {
      return structuredClone(toBeCloned);
    } catch (error) {
      console.error('Error when cloning object, fallback to failsafe method:', { error, toBeCloned });
      return JSON.parse(JSON.stringify(toBeCloned));
    }
  }

  static extractFilenameFromUrl(url: string): string | null {
    try {
      const urlObject = new URL(url);
      const { pathname } = urlObject;
      const filename = pathname.substring(pathname.lastIndexOf('/') + 1);
      return decodeURIComponent(filename);
    } catch (error) {
      console.error('Error parsing URL:', error);
      return null;
    }
  }

  public static filterDuplicates<T extends { id: string }>(of?: T | T[]): OperatorFunction<PaginatedResult<T>, PaginatedResult<T>> {
    const ofArray = Array.isArray(of) ? of : [of];
    return map(value => ({ ...value, content: value.content.filter(element => !ofArray?.map(of => of?.id).includes(element.id)) }));
  }

  public static trackById: TrackByFunction<{ id?: string }> = (index, item) => {
    return item?.id;
  };

  // TODO: recursive typing for keys
  public static convertObjectKeysToCamel<T extends object>(o: T): T {
    const newO = {} as T;
    let origKey: string;
    let newKey: string;
    let value: { [x: string]: unknown; constructor?: Type<unknown> };
    if (o instanceof Array) {
      return o.map(value => {
        if (typeof value === 'object') {
          return TinyHelpers.convertObjectKeysToCamel(value);
        }
        return value;
      }) as T;
    }

    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
        value = o[origKey];
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = TinyHelpers.convertObjectKeysToCamel(value);
        }
        newO[newKey] = value;
      }
    }

    return newO;
  }

  public static uniqueOrNull<TItem>(array: Array<TItem>): TItem {
    if (array.length === 0) {
      return null;
    }
    const testItem = array[0];

    return array.every(item => item === testItem) ? testItem : null;
  }

  /**
   * Remembers the last arguments and results of a function call and skips recalculations if nothing changed. <br>
   * Works like a pure pipe.
   * @param fn The function to memoize
   */
  public static memoizeLatest<FnType extends (...args: unknown[]) => unknown>(fn: FnType): FnType {
    let latestArgs: Parameters<FnType>;
    let latestResult: ReturnType<FnType>;

    function memoizedFn(...args: Parameters<FnType>): ReturnType<FnType> {
      if (args.length === latestArgs?.length && args.every((arg, index) => arg === latestArgs[index])) {
        return latestResult;
      }

      latestArgs = args;
      const result = fn(...args) as ReturnType<FnType>;
      latestResult = result;

      return result;
    }

    return memoizedFn as FnType;
  }

  /**
   * Sleeps until the given milliseconds are reached.
   * @param milliseconds The duration to sleep
   */
  public static sleep(milliseconds: number): Promise<void> {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds);
    });
  }
}
