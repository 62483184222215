import { Component, DestroyRef, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { EmployeeDto, PaginatedResult } from '@coin/shared/util-models';
import { MatDialogRef } from '@angular/material/dialog';
import { StorageService } from '@coin/shared/data-access';
import { StorageKey } from '@coin/shared/util-enums';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { GetEmulationPeopleService, GetImageWithGidService } from '@coin/modules/auth/data-access';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'coin-siemens-energy-select-emulate-person',
  templateUrl: './select-emulate-person.component.html',
  styleUrls: ['./select-emulate-person.component.scss'],
  animations: [
    trigger('shrinkExpand', [
      transition(':enter', [
        style({
          height: '0px',
          minHeight: '0px',
          maxHeight: '0px',
          padding: '0',
          margin: '0',
          opacity: 0,
          overflow: 'hidden'
        }),
        animate(
          '250ms',
          style({
            height: '*',
            minHeight: '*',
            maxHeight: '200px',
            padding: '*',
            margin: '*',
            opacity: 1
          })
        )
      ]),
      transition(':leave', [
        style({ height: '*', minHeight: '*', maxHeight: '200px', padding: '*', margin: '*', opacity: 1 }),
        animate(
          '200ms',
          style({
            height: '0px',
            minHeight: '0px',
            maxHeight: '0px',
            padding: '0',
            margin: '0',
            opacity: 0,
            overflow: 'hidden'
          })
        )
      ])
    ])
  ]
})
export class SelectEmulatePersonComponent implements OnInit {
  public emulateablePersons: PaginatedResult<EmployeeDto> = { content: [], total: 0 };
  public loading = false;

  public user = {
    gid: '',
    name: {
      firstname: '',
      lastname: ''
    }
  };
  public recentlyEmulated: EmployeeDto[] = [];

  constructor(
    private dialogRef: MatDialogRef<SelectEmulatePersonComponent>,
    private getEmulateableUsers: GetEmulationPeopleService,
    private getImage: GetImageWithGidService,
    private storageService: StorageService,
    private destroyRef: DestroyRef
  ) {}

  public ngOnInit(): void {
    this.loadRecentlyEmulated();
  }

  private async loadRecentlyEmulated(): Promise<void> {
    this.recentlyEmulated = (await this.storageService.getAsync(StorageKey.RECENTLY_EMULATED)) ?? [];
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public proceed(userToBeEmulated: EmployeeDto): void {
    this.storageService.setAsync(StorageKey.RECENTLY_EMULATED, [userToBeEmulated, ...this.recentlyEmulated.filter(employee => employee.id !== userToBeEmulated.id)].slice(0, 3));
    this.dialogRef.close(userToBeEmulated);
  }

  public search(): void {
    this.loading = true;
    this.getEmulateableUsers
      .getEmulatePeople(this.user)
      .pipe(
        switchMap(newEmulateableUsers => {
          const userImages: Observable<string>[] = [];
          if (newEmulateableUsers.content && newEmulateableUsers.content.length > 0) {
            for (const user of newEmulateableUsers.content) {
              userImages.push(this.getEmulateUserImage(user.gid));
            }
            return forkJoin(userImages).pipe(
              tap(userImages => {
                for (const [index, user] of newEmulateableUsers.content.entries()) {
                  user['image'] = userImages[index] ? userImages[index] : 'no-image';
                }
              }),
              map(() => newEmulateableUsers)
            );
          }
          return of(newEmulateableUsers);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(newEmulateableUsers => {
        this.emulateablePersons = newEmulateableUsers;
        this.loading = false;
      });
  }

  public getEmulateUserImage(gid: string): Observable<string> {
    return this.getImage.getImageWithStore(gid);
  }
}
