import { PermissionResource } from '@coin/shared/util-enums';
import { TreeNode } from '../route-builder';

export const OrgManagementModule = {
  segment: 'org-management',
  title: '',
  children: {
    OrgManagementComponent: {
      segment: '',
      title: 'org-management.title',
      navigationRoot: true,
      children: {
        OrgManagementOrgMetadataComponent: {
          segment: 'org-metadata',
          title: 'org-management.org-metadata.title',
          icon: 'corporate_fare',
          emptyPage: true,
          children: {
            OrgManagementOrgUnitComponent: {
              segment: 'org-unit',
              title: 'org-management.org-metadata.org-unit'
            },
            OrgManagementOrgTypeComponent: {
              segment: 'org-type',
              title: 'org-management.org-metadata.org-type'
            },
            OrgManagementOrgClassComponent: {
              segment: 'org-class',
              title: 'org-management.org-metadata.org-class'
            }
          }
        },
        OrgManagementBpSrMetadataComponent: {
          segment: 'bp-sr-metadata',
          title: 'org-management.bp-sr-metadata.title',
          icon: 'group',
          emptyPage: true,
          children: {
            OrgManagementBpMetaDataComponent: {
              segment: 'bp',
              title: 'org-management.bp-sr-metadata.bp-type'
            },
            OrgManagementSrMetaDataComponent: {
              segment: 'sr',
              title: 'org-management.bp-sr-metadata.sr-type'
            },
            OrgManagementPositionMetaDataComponent: {
              segment: 'position-type',
              title: 'org-management.bp-sr-metadata.position-type'
            }
          }
        },
        OrgManagementRulesComponent: {
          segment: 'rules-exceptions',
          title: 'org-management.rules-exceptions.title',
          icon: 'fact_check'
        },
        OrgManagementReportingComponent: {
          segment: 'reporting',
          icon: 'show_chart',
          title: 'org-management.reporting.title'
        },
        OrgManagementMassChangesComponent: {
          segment: 'mass-changes',
          icon: 'checklist',
          title: 'org-management.mass-changes.title',
          emptyPage: true,
          children: {
            OrgManagementChangeByRulesComponent: {
              segment: 'rules',
              title: 'org-management.mass-changes.by-rules'
            },
            OrgManagementChangeByDateComponent: {
              segment: 'date',
              title: 'org-management.mass-changes.by-date'
            }
          }
        },
        OrgManagementActivityStreamComponent: {
          segment: 'activity-stream',
          icon: 'history',
          title: 'activity-stream.activity-stream'
        },
        OrgManagementDataHubComponent: {
          segment: 'data-hub',
          icon: 'hub',
          title: 'org-management.data-hub.title',
          permissions: [PermissionResource.All]
        }
      }
    }
  }
} as const satisfies TreeNode;
